import React from "react";

const ConfirmationModal: any = ({ isOpen, onClose, h2Text, pText }: any) => {
  if (!isOpen) return null;

  return (
    <div className="confirmationModal">
      <div className="confirmationModal-content">
        <h2>{h2Text}</h2>
        {pText && <p>{pText}</p>}
        <button onClick={onClose}>Close</button>
      </div>
    </div>
  );
};

export default ConfirmationModal;
