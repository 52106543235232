//@ts-nocheck
import React, { useState, useEffect } from "react";
import "./HomeBody.css";
import { useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import image1 from "../../images/hero/hero1.jpg";
import image2 from "../../images/hero/hero2.jpg";
import image3 from "../../images/hero/hero3.jpg";
import ScrollToTop from "../../shared/helpers/ScrollToTop";

const HomeHero = () => {
  const [navbarHeight, setNavbarHeight]: any = useState();

  useEffect(() => {
    const navbar: any = document.getElementsByClassName("navbarClass")[0];
    setNavbarHeight(navbar.offsetHeight);
  }, []);
  const heroItems = [
    {
      header: "",
      text1: "Elevate Your Wellness With",
      text2: "Our Beauty Tools",
      image: image1,
      button: "EXPLORE MORE",
      link: "/shop",
    },
    {
      header: "",
      text1: "Discover the Secrets of",
      text2: "Radiant Beauty",
      image: image2,
      button: "DISCOVER NOW",
      link: "/shop",
    },
    {
      header: "",
      text1: "Explore Our Exclusive Sets",
      text2: "for Beautiful Results",
      image: image3,
      button: "SHOP SETS",
      link: "/shop?type=bundles",
    },
  ];
  const navigate = useNavigate();
  return (
    <div className="heroContainer">
      <Swiper
        // style={{ paddingTop: navbarHeight, height: "100vh" }}
        pagination={true}
        modules={[Pagination]}
        className="mySwiper"
      >
        {heroItems.map((item: any) => {
          return (
            <SwiperSlide>
              <img src={item.image} alt="" />
              <div className="imageOverlay"></div>
              <div className="homeShopButtonContainer">
                <div className="homeHeroHeader">{item.header}</div>
                <div className="homeHeroText">
                  {item.text1}
                  <br></br>
                  {item.text2}
                </div>
                <div
                  className="homeShopButton cursorPointer"
                  onClick={() => {
                    navigate(item.link);
                  }}
                >
                  {item.button}
                </div>
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
};

export default HomeHero;
