import React, { useState, useEffect } from "react";
import "./SmallPages.css";
import aboutUs from "../../images/aboutUs.jpg";
import { Container, Col, Row } from "react-bootstrap";

const Shipping = () => {
  const [navbarHeight, setNavbarHeight]: any = useState();

  useEffect(() => {
    const navbar: any = document.getElementsByClassName("navbarClass")[0];
    setNavbarHeight(navbar.offsetHeight);
  }, []);

  const shippingItems = [
    {
      question: "Shipping",
      answer:
        "We strive to provide a seamless and efficient shipping experience for our customers. Currently, we offer shipping services exclusively within Lebanon. We have partnered with Wakilni to ensure your orders are delivered promptly all over lebanon.",
    },
    {
      question: "Delivery Time",
      answer:
        "Our estimated delivery time within Lebanon is 3-5 business days. Please note that unforeseen circumstances or high-demand periods may occasionally result in slight delays. Rest assured, our team is committed to keeping you informed throughout the shipping process.",
    },

    {
      question: "Payment Options",
      answer:
        "We currently accept cash on delivery (COD) as the payment method for all orders. This convenient option allows you to pay for your products upon delivery, ensuring a hassle-free shopping experience. Our delivery personnel will collect the payment in cash when they hand over your order",
    },
    {
      question: "Returns and Refunds",
      answer:
        "We want you to be completely satisfied with your SkinSoul purchase. If, for any reason, you are not satisfied with your order, please contact our customer care team within 7 days of receiving your products. We will gladly assist you with the return process and provide a refund or exchange, subject to our return policy. Please note that returned items must be unused, in their original packaging, and accompanied by proof of purchase.",
    },
    {
      question: "International Shipping",
      answer:
        "Currently, we do not offer international shipping. However, we are actively working on expanding our shipping capabilities to serve customers worldwide. We appreciate your patience and understanding as we strive to make SkinSoul products available in more countries. Stay tuned for updates on our international shipping services.",
    },
  ];
  return (
    <div style={{ paddingTop: navbarHeight, paddingBottom: "30px" }}>
      <Container>
        <Row>
          <Col>
            <div className="contactPage shippingPage">
              <h3 className="contactPageheader">
                <strong>Shipping & Returns</strong>
              </h3>
              {shippingItems.map((item: any, index: any) => {
                return (
                  <>
                    <h5>{item.question}</h5>
                    {item.answer}
                    {index !== shippingItems.length - 1 && (
                      <div className="shippingPageLine"></div>
                    )}
                  </>
                );
              })}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Shipping;
