import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import { gsap } from "gsap";
import { useNavigate } from "react-router-dom";
import {
  staggerText,
  staggerReveal,
  fadeInUp,
  staggerRevealClose,
} from "./MenuAnimations";
import { Container, Col, Row } from "react-bootstrap";
import instagramIcon from "../../images/instagramIconDark.svg";
import whatsappIcon from "../../images/whatsappDark.svg";
import ScrollToTop from "../helpers/ScrollToTop";

const NavbarMenu: React.FC<{
  menuState: any;
  disabled: any;
  handleMenu: any;
  anim: any;
}> = ({ menuState, disabled, handleMenu, anim }) => {
  const navigate = useNavigate();
  // Create varibles of our dom nodes
  let menuLayer: any = useRef(null);
  let reveal1: any = useRef(null);
  let reveal2: any = useRef(null);
  let line1: any = useRef(null);
  let line2: any = useRef(null);
  let line3: any = useRef(null);
  let line4: any = useRef(null);
  let line5: any = useRef(null);
  let line6: any = useRef(null);
  let info: any = useRef(null);

  useEffect(() => {
    // If the menu is open and we click the menu button to close it.
    if (menuState.clicked === false) {
      // If menu is closed and we want to open it.

      staggerRevealClose(reveal2, reveal1);
      // Set menu to display none
      gsap.to(menuLayer, { duration: 1, css: { display: "none" } });
    } else if (
      menuState.clicked === true ||
      (menuState.clicked === true && menuState.initial === null)
    ) {
      // Set menu to display block
      gsap.to(menuLayer, { duration: 0, css: { display: "block" } });
      //Allow menu to have height of 100%
      gsap.to([reveal1, reveal2], {
        duration: 0,
        opacity: 1,
        width: "100vw",
      });
      staggerReveal(reveal1, reveal2);
      fadeInUp(info);
      staggerText([line1, line2, line3, line4, line5]);
    }
  }, [menuState]);

  const handleOnClickMenu = (link: any) => {
    if (!disabled) {
      handleMenu();
      anim.current?.setDirection(menuState.clicked ? -1 : 1);
      if (!menuState.clicked) {
        anim.current?.playSegments([0, 25], true);
      } else {
        anim.current?.playSegments([25, 0], true);
      }
    }
    navigate(link);
  };

  const [isShopOpen, setIsShopOpen] = useState(false);
  const handleClick = (url: any) => {
    window.open(url);
  };
  return (
    <div ref={(el) => (menuLayer = el)} className="hamburger-menu">
      <div
        ref={(el) => (reveal1 = el)}
        className="menu-secondary-background-color"
      ></div>
      <div ref={(el) => (reveal2 = el)} className="menu-layer">
        <div className="container">
          <div className="wrapper">
            <div className="menu-links">
              <Container>
                <Row>
                  <Col>
                    <div
                      className="navbarItem"
                      ref={(el) => (line1 = el)}
                      onClick={() => {
                        setIsShopOpen(!isShopOpen);
                      }}
                    >
                      <div className="d-flex justify-content-between">
                        SHOP
                        <div className="navbarShopIcon">
                          {isShopOpen ? <>-</> : <>+</>}
                        </div>
                      </div>

                      <div
                        className={`hiddenShop ${
                          isShopOpen ? "navbarShop" : ""
                        }`}
                      >
                        <div
                          className="navbarShopItem"
                          onClick={() => {
                            handleOnClickMenu("/shop");
                          }}
                        >
                          All Products
                        </div>
                        <div
                          className="navbarShopItem"
                          onClick={() => {
                            handleOnClickMenu("/shop?type=skincare");
                          }}
                        >
                          Skincare
                        </div>
                        <div
                          className="navbarShopItem"
                          onClick={() => {
                            handleOnClickMenu("/shop?type=body");
                          }}
                        >
                          Body
                        </div>
                        <div
                          className="navbarShopItem"
                          onClick={() => {
                            handleOnClickMenu("/shop?type=hair");
                          }}
                        >
                          Hair
                        </div>
                        <div
                          className="navbarShopItem"
                          onClick={() => {
                            handleOnClickMenu("/shop?type=bundles");
                          }}
                        >
                          Bundles And Sets
                        </div>
                      </div>
                      <div className="navbarLine"></div>
                    </div>

                    <div
                      ref={(el) => (line2 = el)}
                      onClick={() => {
                        handleOnClickMenu("/story");
                      }}
                      className="navbarItem"
                    >
                      OUR STORY
                      <div className="navbarLine"></div>
                    </div>

                    {/* <div
                      className="navbarItem"
                      ref={(el) => (line3 = el)}
                      onClick={() => {
                        handleOnClickMenu("/stones");
                      }}
                    >
                      STONE GUIDE
                      <div className="navbarLine"></div>
                    </div> */}
                    <div
                      className="navbarItem"
                      ref={(el) => (line3 = el)}
                      onClick={() => {
                        handleOnClickMenu("/contact");
                      }}
                    >
                      CONTACT
                    </div>
                    <div
                      className="navbarItem"
                      ref={(el) => (line4 = el)}
                    ></div>
                    <div className="socialLinks" ref={(el) => (line5 = el)}>
                      <img
                        src={instagramIcon}
                        alt=""
                        onClick={() => {
                          handleClick("https://www.instagram.com/skinsoul.lb/");
                        }}
                      />{" "}
                      <img
                        src={whatsappIcon}
                        alt=""
                        onClick={() => {
                          handleClick("https://wa.me/message/O5JSZUIHRKMGB1");
                        }}
                      />
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NavbarMenu;
