import React from "react";
import { Container, Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import ScrollToTop from "../../shared/helpers/ScrollToTop";
const HomeBundles = () => {
  const navigate = useNavigate();
  return (
    <div className="homeBundles">
      <Container>
        <Row>
          <Col>
            <div className="homeBundlesContainer">
              <div className="homeBundlesHeader">Skin Soul Essentials</div>
              <div className="homeBundlesBody">
                Enhance your skincare routine with our premium Beauty tools.
              </div>
              {/* <div className="homeBundlesFooter">
                Crafted with the highest quality materials, our Gua Sha set is
                curated to help you get the most out of your gua sha routine.
              </div> */}
              <div
                className="homeBundlesButton"
                onClick={() => {
                  navigate("/shop?type=bundles");
                }}
              >
                VIEW SETS
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default HomeBundles;
