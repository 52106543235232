import React, { useEffect, useState } from "react";
import { Routes, Route, BrowserRouter, useLocation } from "react-router-dom";
import ScrollToTop from "./shared/helpers/ScrollToTop";
import Navbar from "./shared/navbar/Navbar";
import HomeBody from "./pages/home/HomeBody";
import "./App.css";
import Footer from "./shared/footer/Footer";
import { Provider } from "react-redux";
import ShopBody from "./pages/shop/ShopBody";
import ShopItem from "./pages/shop/ShopItem";
import CheckoutBody from "./pages/checkout/CheckoutBody";
import AboutUs from "./pages/smallPages/AboutUs";
import Contact from "./pages/smallPages/Contact";
import PrivacyPolicy from "./pages/smallPages/PrivacyPolicy";
import Shipping from "./pages/smallPages/Shipping";
import Faq from "./pages/smallPages/Faq";
import StoneGuide from "./pages/smallPages/StoneGuide";

function App() {
  const [isCart, setIsCart] = useState(false);

  return (
    <div className="App">
      <BrowserRouter>
        <Navbar isCart={isCart} setIsCart={setIsCart}></Navbar>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<HomeBody />} />
          <Route path="/shop" element={<ShopBody />} />
          <Route
            path="/item/:itemId"
            element={<ShopItem isCart={isCart} setIsCart={setIsCart} />}
          />
          <Route path="/checkout" element={<CheckoutBody />} />
          <Route path="/story" element={<AboutUs />} />
          <Route path="/privacypolicy" element={<PrivacyPolicy />} />
          <Route path="/FAQ" element={<Faq />} />
          <Route path="/shipping" element={<Shipping />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/stones" element={<StoneGuide />} />
        </Routes>
        <Footer></Footer>
      </BrowserRouter>
    </div>
  );
}

export default App;
