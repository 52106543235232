import React, { useState, useEffect } from "react";
import "./SmallPages.css";
import aboutUs from "../../images/aboutUs.jpg";
import { Container, Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { FaPlus, FaMinus } from "react-icons/fa";
import ScrollToTop from "../../shared/helpers/ScrollToTop";

const Faq = () => {
  const navigate = useNavigate();
  const [navbarHeight, setNavbarHeight]: any = useState();

  useEffect(() => {
    const navbar: any = document.getElementsByClassName("navbarClass")[0];
    setNavbarHeight(navbar.offsetHeight);
  }, []);
  const faqItems = [
    {
      question: "What is a gua sha tool, and how do I use it?",
      answer:
        "A gua sha tool is a handheld stone tool used in traditional Chinese medicine for facial massage. To use it, apply a facial oil or serum, then gently glide the tool along your face and neck in upward and outward motions to promote circulation, lymphatic drainage, and a youthful glow.",
    },
    {
      question:
        "How long does it take to see results from using your skincare products?",
      answer:
        "The timeframe for seeing results may vary depending on the individual and the specific product. However, many of our customers have reported noticeable improvements in their skin's appearance and texture within a few weeks of consistent use. Remember, skincare is a journey, and patience is key to achieving long-term results.",
    },
    {
      question: "Are your products suitable for sensitive skin?",
      answer:
        "Yes, our products are formulated with gentle and nourishing ingredients to be suitable for sensitive skin. However, as everyone's skin is unique, we recommend performing a patch test before applying new products to your face. If you have specific concerns or allergies, we advise consulting with a dermatologist before introducing any new skincare regimen.",
    },
    {
      question: "Do you offer international shipping?",
      answer:
        "Currently, we primarily ship within Lebanon. While we don't offer international shipping at the moment, we are actively working towards expanding our reach and making our products available worldwide. We appreciate your interest and patience as we strive to bring SkinSoul to customers around the globe. ",
    },
    {
      question: "Are your products cruelty-free and vegan?",
      answer:
        "Yes, we are proud to be a cruelty-free brand, and none of our products are tested on animals. Additionally, many of our products are vegan-friendly, but please refer to the product descriptions or labels for specific information regarding their vegan status.",
    },
    {
      question: "How often should I use a face mask",
      answer:
        "The frequency of using a face mask depends on the product and your skin's needs. Generally, we recommend using a face mask 1-2 times a week to supplement your regular skincare routine. However, always follow the instructions provided with each specific product for the best results.",
    },
    {
      question: "Do you offer gift wrapping or personalized notes?",
      answer:
        "Yes, we offer gift wrapping services for a special touch. During the checkout process, you can select the gift wrap option and include a personalized note for your recipient. This way, you can create a thoughtful and unique gift experience.",
    },
  ];

  const [openedItems, setOpenedItems]: any = useState([]);

  const toggleItem = (index: any) => {
    if (openedItems.includes(index)) {
      setOpenedItems(openedItems.filter((item: any) => item !== index));
    } else {
      setOpenedItems([...openedItems, index]);
    }
  };

  const renderQuestion = (item: any, index: any) => {
    const isOpen = openedItems.includes(index);
    const icon = isOpen ? <FaMinus /> : <FaPlus />;

    return (
      <div
        key={index}
        className="faqQuestion"
        onClick={() => toggleItem(index)}
      >
        <div>{item.question}</div>
        <div>{icon}</div>
      </div>
    );
  };

  const renderAnswer = (item: any, index: any) => {
    if (!openedItems.includes(index)) {
      return null;
    }

    return (
      <div className="faqAnswer" key={index}>
        {item.answer}
      </div>
    );
  };
  return (
    <div style={{ paddingTop: navbarHeight, paddingBottom: "40px" }}>
      <Container>
        <Row>
          <Col>
            <div className="contactPage">
              <h2 className="contactPageheader">
                <strong>
                  Frequently Asked<br></br>Questions
                </strong>
              </h2>
              We understand that you may have questions, and we're here to
              provide the answers. If you can't find the information you're
              looking for, please don't hesitate to{" "}
              <strong
                onClick={() => {
                  navigate("/contact");
                }}
              >
                contact us
              </strong>
              .
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            {faqItems.map((item, index) => (
              <div key={index}>
                {renderQuestion(item, index)}
                {renderAnswer(item, index)}
              </div>
            ))}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Faq;
