import React from "react";
import { Container, Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import ScrollToTop from "../../shared/helpers/ScrollToTop";
import { useWindowWidth } from "../../shared/helpers/windowWidth";
const HomeCategories = () => {
  const windowWidth = useWindowWidth();
  const navigate = useNavigate();
  const mobileVersion = (
    <div className="homeCategories reveal">
      <Container>
        <Row>
          <Col>
            <div className="homeCategoriesHeader">
              Distilled from ancient Chinese wisdom for a modern world.
            </div>
            <div className="homeCategoriesItem homeCategoriesItem1 mt-4 mb-3">
              <div className="homeCategoriesItemTitle">SKINCARE</div>
              <div
                className="homeCategoriesItemButton"
                onClick={() => {
                  navigate("/shop?type=skincare");
                }}
              >
                VIEW PRODUCTS
              </div>
            </div>
            <div className="homeCategoriesItem homeCategoriesItem2 mb-3">
              <div className="homeCategoriesItemTitle">HAIR</div>
              <div
                className="homeCategoriesItemButton"
                onClick={() => {
                  navigate("/shop?type=hair");
                }}
              >
                VIEW PRODUCTS
              </div>
            </div>
            <div className="homeCategoriesItem homeCategoriesItem3 mb-3">
              <div className="homeCategoriesItemTitle">BODY</div>
              <div
                className="homeCategoriesItemButton"
                onClick={() => {
                  navigate("/shop?type=body");
                }}
              >
                VIEW PRODUCTS
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );

  const laptopVersion = (
    <div className="homeCategories reveal">
      <Container>
        <Row>
          <Col md={12} lg={12}>
            <div className="homeCategoriesHeader">
              Distilled from ancient Chinese wisdom for a modern world.
            </div>
          </Col>
        </Row>
        <Row className=" mt-4">
          <Col md={4} lg={4}>
            <div className="homeCategoriesItem homeCategoriesItem1 mb-3">
              <div className="homeCategoriesItemTitle">SKINCARE</div>
              <div
                className="homeCategoriesItemButton"
                onClick={() => {
                  navigate("/shop?type=skincare");
                }}
              >
                VIEW PRODUCTS
              </div>
            </div>
          </Col>
          <Col md={4} lg={4}>
            <div className="homeCategoriesItem homeCategoriesItem2 mb-3">
              <div className="homeCategoriesItemTitle">HAIR</div>
              <div
                className="homeCategoriesItemButton"
                onClick={() => {
                  navigate("/shop?type=hair");
                }}
              >
                VIEW PRODUCTS
              </div>
            </div>
          </Col>
          <Col md={4} lg={4}>
            <div className="homeCategoriesItem homeCategoriesItem3 mb-3">
              <div className="homeCategoriesItemTitle">BODY</div>
              <div
                className="homeCategoriesItemButton"
                onClick={() => {
                  navigate("/shop?type=body");
                }}
              >
                VIEW PRODUCTS
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <div className="dividerLine"></div>
    </div>
  );

  if (windowWidth < 700) {
    return mobileVersion;
  } else return laptopVersion;
};

export default HomeCategories;
