// @ts-nocheck
import React from "react";
import "./HomeBody.css";
import HomeHero from "./HomeHero";
import HomeAbout from "./HomeAbout";
import HomeCategories from "./HomeCategories";
import HomeBundles from "./HomeBundles";
import HomeStones from "./HomeStones";
import HomeFeatured from "./HomeFeatured";
import HomeInsta from "./HomeInsta";

const HomeBody = () => {
  function reveal() {
    var reveals = document.querySelectorAll(".reveal");
    for (var i = 0; i < reveals.length; i++) {
      var windowHeight = window.innerHeight;
      var revealTop = reveals[i].getBoundingClientRect().top;
      var revealPoint = -20;

      if (revealTop < windowHeight - revealPoint) {
        reveals[i].classList.add("active");
      } else {
        reveals[i].classList.remove("active");
      }
    }
  }
  return (
    <div className="homeBody">
      <HomeHero></HomeHero>
      <HomeFeatured></HomeFeatured>
      <HomeCategories></HomeCategories>
      <HomeAbout></HomeAbout>
      <HomeBundles></HomeBundles>
      {/* <HomeStones></HomeStones> */}
      <HomeInsta></HomeInsta>
      <script type="text/javascript">
        {window.addEventListener("scroll", reveal)}
      </script>
    </div>
  );
};

export default HomeBody;
