import React, { useRef, useEffect, useState } from "react";
import im1 from "../../images/singleGua.jpg";
import im2 from "../../images/singleFaceRoller.jpg";
import im3 from "../../images/singleHairComb.jpg";
import { motion } from "framer-motion";
import { Container, Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import ScrollToTop from "../../shared/helpers/ScrollToTop";
import { useWindowWidth } from "../../shared/helpers/windowWidth";
import { items } from "../../items";

const HomeFeatured = () => {
  const windowWidth = useWindowWidth();
  const navigate = useNavigate();
  const pinkGuaSha = items.find(
    (item: any) => item.name === "Rose Quartz Gua Sha"
  );
  const jadeFaceRoller = items.find(
    (item: any) => item.name === "Jade Face Roller"
  );
  const bambooBrush = items.find((item: any) => item.name === "Bamboo Brush");

  const itemsArray = [pinkGuaSha, jadeFaceRoller, bambooBrush];

  const [width, setWidth] = useState(0);
  const carousel: any = useRef();

  useEffect(() => {
    console.log(
      carousel?.current?.scrollWidth - carousel?.current?.offsetWidth
    );
    setWidth(carousel?.current?.scrollWidth - carousel?.current?.offsetWidth);
  }, []);

  useEffect(() => {
    console.log(width);
  }, [width]);
  const mobileVersion = (
    <div className="homeCarouselContainer reveal">
      <h3 className="homeFeaturedCollection mb-5">
        FEATURED<br></br> COLLECTION
      </h3>
      <motion.div
        ref={carousel}
        className="homeCarousel"
        whileTap={{ cursor: "grabbing" }}
      >
        <motion.div
          drag="x"
          dragConstraints={{ right: 0, left: -width }}
          className="homeInnerCarousel"
        >
          {itemsArray.map((item: any, index: any) => {
            return (
              <motion.div className="item" key={index}>
                <Container>
                  <Row>
                    <Col>
                      <div
                        className="homeSliderImage"
                        style={{
                          backgroundImage: `url(${item.img})`,
                        }}
                        onClick={() => {
                          navigate(`/item/${item.id}`);
                        }}
                      ></div>
                      <div className="homeSliderDescription mt-4">
                        {item.name}
                        <br></br>
                        {item.price}$
                      </div>
                    </Col>
                  </Row>
                </Container>
              </motion.div>
            );
          })}
        </motion.div>
      </motion.div>
      <div className="d-flex justify-content-center align-items-center mt-5">
        <div
          className="homeFeaturedButton shopAllButton"
          onClick={() => {
            navigate("/shop");
          }}
        >
          SHOP ALL
        </div>
      </div>
      <div className="dividerLine"></div>
    </div>
  );

  const laptopVersion = (
    <div className="reveal">
      <h3 className="homeFeaturedCollection mb-5">
        FEATURED<br></br> COLLECTION
      </h3>
      <Container>
        <Row>
          {itemsArray.map((item: any, index: any) => {
            return (
              <Col md={4} lg={4}>
                <div className="homeDesktopFeaturedImageContainer">
                  {" "}
                  <div
                    className="homeDesktopFeaturedImage"
                    style={{
                      backgroundImage: `url(${item.img})`,
                    }}
                    onClick={() => {
                      navigate(`/item/${item.id}`);
                    }}
                  ></div>
                </div>

                <div className="homeSliderDescription mt-4">
                  {item.name}
                  <br></br>
                  {item.price}$
                </div>
              </Col>
            );
          })}
        </Row>
      </Container>
      <div className="d-flex justify-content-center align-items-center mt-5">
        <div
          className="homeFeaturedButton shopAllButton "
          onClick={() => {
            navigate("/shop");
          }}
        >
          SHOP ALL
        </div>
      </div>
      <div className="dividerLine"></div>
    </div>
  );
  if (windowWidth < 700) {
    return mobileVersion;
  } else return laptopVersion;
};

export default HomeFeatured;
