import React, { useState } from "react";
import { Container, Col, Row } from "react-bootstrap";
import "./Footer.css";
import instagramIcon from "../../images/instagramIconDark.svg";
import whatsappIcon from "../../images/whatsappDark.svg";
import { useNavigate } from "react-router-dom";
import ScrollToTop from "../helpers/ScrollToTop";
import { useWindowWidth } from "../helpers/windowWidth";
import ConfirmationModal from "../../pages/checkout/ConfirmationModal";

const Footer = () => {
  const windowWidth = useWindowWidth();
  const [inputValue, setInputValue] = useState("");

  const handleChange = (event: any) => {
    setInputValue(event.target.value);
  };
  const handleClick = (url: any) => {
    window.open(url);
  };
  const navigate = useNavigate();

  const [showModal, setShowModal] = useState(false);

  const handleCloseModal = () => {
    setShowModal(false);
  };
  const mobileVersion = (
    <div className="footer">
      <Container>
        <Row>
          <Col>
            <div className="footerHeader">SkinSoul</div>{" "}
            <div className="footerSubscribeText">
              Subscribe to receive updates, access to exclusive deals, and more.
            </div>
            <input
              type="text"
              value={inputValue}
              onChange={handleChange}
              placeholder="Your email"
              className="footerSubscribeEmail"
            />
            <div
              className="footerSubscribeButton"
              onClick={() => {
                setInputValue("");
                setShowModal(true);
              }}
            >
              SUBSCRIBE
            </div>
            <div className="footerIcons"></div>
            <div className="d-flex justify-content-between">
              <div className="footerLinks">
                <div
                  className="footerLink"
                  onClick={() => {
                    navigate("/story");
                  }}
                >
                  About
                </div>
                <div
                  className="footerLink"
                  onClick={() => {
                    navigate("/contact");
                  }}
                >
                  Contact Us
                </div>
                <div
                  className="footerLink"
                  onClick={() => {
                    navigate("/FAQ");
                  }}
                >
                  FAQ
                </div>
                <div
                  className="footerLink"
                  onClick={() => {
                    navigate("/shipping");
                  }}
                >
                  Shipping & Returns{" "}
                </div>
                <div
                  className="footerLink"
                  onClick={() => {
                    navigate("/privacypolicy");
                  }}
                >
                  Privacy Policy
                </div>
              </div>
              <div className="footerContactLinks">
                <div className="footerLink">
                  <img
                    src={instagramIcon}
                    alt=""
                    onClick={() => {
                      handleClick("https://www.instagram.com/skinsoul.lb/");
                    }}
                  />{" "}
                  <img
                    src={whatsappIcon}
                    alt=""
                    onClick={() => {
                      handleClick("https://wa.me/message/O5JSZUIHRKMGB1");
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="footerRef">@ SkinSoul 2023</div>
          </Col>
        </Row>
      </Container>
      <ConfirmationModal
        isOpen={showModal}
        onClose={handleCloseModal}
        h2Text="Thank You for Subscribing"
        pText="You have successfully subscribed to our newsletter. You will now receive updates, special offers, and the latest news straight to your inbox."
      />
    </div>
  );
  const laptopVersion = (
    <div className="footer laptopFooter">
      <Container>
        <Row>
          <div className="footerHeader">SkinSoul</div>{" "}
        </Row>
        <Row className="footerRowLaptop">
          <Col>
            <div className="footerIcons"></div>
            <div className="d-flex justify-content-between">
              <Col>
                {" "}
                <div className="footerLinks">
                  <div
                    className="footerLink"
                    onClick={() => {
                      navigate("/story");
                    }}
                  >
                    About
                  </div>
                  <div
                    className="footerLink"
                    onClick={() => {
                      navigate("/contact");
                    }}
                  >
                    Contact Us
                  </div>{" "}
                  <div
                    className="footerLink"
                    onClick={() => {
                      navigate("/FAQ");
                    }}
                  >
                    FAQ
                  </div>
                </div>
              </Col>
              <Col>
                {" "}
                <div className="footerLinks">
                  <div
                    className="footerLink"
                    onClick={() => {
                      navigate("/shipping");
                    }}
                  >
                    Shipping & Returns{" "}
                  </div>
                  <div
                    className="footerLink"
                    onClick={() => {
                      navigate("/privacypolicy");
                    }}
                  >
                    Privacy Policy
                  </div>
                </div>
                <div className="footerContactLinks">
                  <div className="footerLink">
                    <img
                      src={instagramIcon}
                      alt=""
                      onClick={() => {
                        handleClick("https://www.instagram.com/skinsoul.lb/");
                      }}
                    />{" "}
                    <img
                      src={whatsappIcon}
                      alt=""
                      onClick={() => {
                        handleClick("https://wa.me/message/O5JSZUIHRKMGB1");
                      }}
                    />
                  </div>
                </div>
              </Col>
            </div>
            <div className="footerRef">@ Skin Soul Beauty Copyright 2023</div>
          </Col>
          <Col>
            <div className="footerSubscribeText">
              Subscribe to receive updates, access to exclusive deals, and more.
            </div>
            <div className="footerSubscribeLaptop">
              <input
                type="text"
                value={inputValue}
                onChange={handleChange}
                placeholder="Your email"
                className="footerSubscribeEmail"
              />
              <div
                className="footerSubscribeButton"
                onClick={() => {
                  setShowModal(true);
                  setInputValue("");
                }}
              >
                SUBSCRIBE
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <ConfirmationModal
        isOpen={showModal}
        onClose={handleCloseModal}
        h2Text="Thank You for Subscribing"
        pText="You have successfully subscribed to our newsletter. You will now receive updates, special offers, and the latest news straight to your inbox."
      />
    </div>
  );
  if (windowWidth < 700) {
    return mobileVersion;
  } else return laptopVersion;
};

export default Footer;
