import gsap from "gsap";
import "./NavbarAnimation.css";

// OPEN MENU
export const staggerReveal = (node1: any, node2: any) => {
  gsap.from([node1, node2], {
    duration: 0.8,
    width: 0,
    transformOrigin: "right top",
    skewY: 2,
    ease: "power3.inOut",
    stagger: {
      amount: 0.1,
    },
  });
};

// CLOSE MENU
export const staggerRevealClose = (node1: any, node2: any) => {
  gsap.to([node1, node2], {
    duration: 0.8,
    width: 0,
    ease: "power3.inOut",
    stagger: {
      amount: 0.07,
    },
  });
};

// STAGGER THE LINKS TO APPEAR
export const staggerText = (nodes: any) => {
  gsap.from(nodes, {
    duration: 0.8,
    y: 100,
    delay: 0.1,
    ease: "power3.inOut",
    stagger: {
      amount: 0.3,
    },
  });
};

// Fade up for the additonal info on our menu
export const fadeInUp = (node: any) => {
  gsap.from(node, {
    y: 60,
    duration: 1,
    delay: 0.2,
    opacity: 0,
    ease: "power3.inOut",
  });
};
