import React, { useState, useRef, useEffect } from "react";
import "./Navbar.css";
import { Container, Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import menuLottie from "../../images/menuAnimation.json";
import { useSelector } from "react-redux";
import lottie from "lottie-web";
import NavbarMenu from "./NavbarMenu";
import MenuCart from "./MenuCart";
import ScrollToTop from "../helpers/ScrollToTop";
import logo from "../../images/skinsoulLogo.png";

const Navbar: React.FC<any> = ({ isCart, setIsCart }) => {
  const cart = useSelector((state) => state);
  const navigate = useNavigate();
  const [selected, setSelected] = useState(0);

  const animationContainer: any = useRef(null);
  const anim: any = useRef(null);

  // State of our Menu
  const [menuState, setMenuState]: any = useState({
    initial: false,
    clicked: null,
  });

  // State of our button
  const [disabled, setDisabled] = useState(false);

  //Determine if out menu button should be disabled
  const disableMenu = () => {
    setDisabled(true);
    setTimeout(() => {
      setDisabled(false);
    }, 1000);
  };

  // Toggle menu
  const handleMenu = () => {
    disableMenu();
    if (menuState.initial === false) {
      setMenuState({
        initial: null,
        clicked: true,
      });
    } else if (menuState.clicked === true) {
      setMenuState({
        clicked: !menuState.clicked,
      });
    } else if (menuState.clicked === false) {
      setMenuState({
        clicked: !menuState.clicked,
      });
    }
  };

  // Load lottie
  useEffect(() => {
    if (animationContainer.current) {
      anim.current = lottie.loadAnimation({
        container: animationContainer.current,
        renderer: "svg",
        loop: false,
        autoplay: false,
        animationData: menuLottie,
      });

      return () => anim.current?.destroy();
    }
  }, []);
  const [windowWidth, setWindowWidth]: any = useState();

  const handleWindowResize = () => {
    setWindowWidth(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);
  return (
    <div className="navbarClass">
      <NavbarMenu
        menuState={menuState}
        handleMenu={handleMenu}
        disabled={disabled}
        anim={anim}
      />
      <Container>
        <Row>
          <Col className="navbarContainer">
            <div>
              {/* {windowWidth > 990 ? (
                <div className="navbarItems">
                  <div
                    className={
                      selected === 0 ? "navbarItemSelected" : "navbarItem"
                    }
                    onClick={() => {
                      navigate("/");
                      setSelected(0);
                      
                    }}
                  >
                    Home
                  </div>
                </div>
              ) : ( */}
              <div
                className="menuCircle"
                onClick={() => {
                  if (!disabled) {
                    handleMenu();
                    anim.current?.setDirection(menuState.clicked ? -1 : 1);
                    if (!menuState.clicked) {
                      anim.current?.playSegments([0, 25], true);
                    } else {
                      anim.current?.playSegments([25, 0], true);
                    }
                  }
                }}
              >
                <div id="menuLottie" ref={animationContainer}></div>
              </div>
            </div>
            <div
              className="navbarHeader"
              onClick={() => {
                navigate("/");
              }}
            >
              <img src={logo} className="skinsoulLogoTest" alt=""></img>
              SkinSoul
            </div>
            <MenuCart isCart={isCart} setIsCart={setIsCart}></MenuCart>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Navbar;
