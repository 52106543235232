import React, { useState, useEffect } from "react";
import "./SmallPages.css";
import aboutUs from "../../images/aboutUs.jpg";
import { Container, Col, Row } from "react-bootstrap";
import aboutUsFounder from "../../images/aboutUsFounder.jpg";
import qualityIcon from "../../images/qualityIcon.svg";
import ecoIcon from "../../images/ecofriendlyIcon.svg";
import customerIcon from "../../images/customerIcon.svg";
import wellbeingIcon from "../../images/wellbeingIcon.svg";
import { useWindowWidth } from "../../shared/helpers/windowWidth";

const AboutUs = () => {
  const windowWidth = useWindowWidth();
  const [navbarHeight, setNavbarHeight]: any = useState();

  useEffect(() => {
    const navbar: any = document.getElementsByClassName("navbarClass")[0];
    setNavbarHeight(navbar.offsetHeight);
  }, []);
  const philosophyItems = [
    {
      name: "Quality Craftsmanship",
      icon: qualityIcon,
      text: "Meticulously crafted skincare products with attention to detail and highest standards",
    },
    {
      name: "Eco-friendly",
      icon: ecoIcon,
      text: "Committed to sustainable practices and reducing our environmental footprint",
    },
    {
      name: "Customer Empowerment",
      icon: customerIcon,
      text: "Empowering our customers with personalized guidance, expert insights, and resources",
    },
    {
      name: "Holistic Well-being",
      icon: wellbeingIcon,
      text: "Embracing the connection between inner and outer beauty for overall wellness.",
    },
  ];
  return (
    <div style={{ paddingTop: navbarHeight }}>
      <div className="aboutUsImageContainer aboutUsImageContainer1">
        <img src={aboutUs} alt="" className="aboutUsImage aboutUsImage1" />
        <div className="aboutUsImageOverlay"></div>{" "}
        <h4 className="aboutUsImageText">
          Embrace the transformative power of skincare
        </h4>
      </div>

      <div className="aboutUsCol">
        <Container>
          <Row>
            <Col>
              <h2>Our Story</h2>
              <div className="aboutUsText">
                At SkinSoul, we believe that skincare is a transformative
                journey. We strive to empower individuals by offering an
                exceptional selection of skincare products crafted with care and
                expertise. Our mission is to help you achieve the radiant
                transformation you deserve.
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <div className="aboutUsImageContainer aboutUsImageContainer2">
        <img
          src={aboutUsFounder}
          alt=""
          className="aboutUsImage aboutUsFounderImage"
        />
        <div className="aboutUsImageOverlay"></div>
      </div>
      <Container>
        <Row>
          <Col className="aboutUsCol">
            <h2 className="aboutUsTitle">Meet our founder</h2>
            <div className="aboutUsText">
              At the heart of SkinSoul is our founder Thea, whose passion for
              skincare and commitment to natural beauty led to the creation of
              our brand.<br></br> With a deep understanding of the
              transformative power of self-care, Thea's vision is reflected in
              every product we offer, inspiring individuals to embrace their
              unique beauty and radiate confidence
            </div>
          </Col>
        </Row>
      </Container>
      <div className="aboutUsPhilosophy">
        <Container>
          <Row>
            <Col>
              <div className="aboutUsPhilosophyWrapper">
                <h2 className="aboutUsPhilosophyHeader">Our Philosophy</h2>
              </div>
              {philosophyItems.map((item: any) => {
                return (
                  <div className="philosophyItem">
                    <div className="philosophyItemTitle">
                      <hr className="philosophyItemLine" />
                      <img src={item.icon} alt="" />
                      <hr className="philosophyItemLine" />
                    </div>
                    <h4 className="philosophyName">{item.name}</h4>
                    <div className="philosophyDescription">{item.text}</div>
                  </div>
                );
              })}
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default AboutUs;
