import React, { useEffect, useState, useRef } from "react";
import cartIcon from "../../images/cartPink.png";
import { useSelector, useDispatch } from "react-redux";
import { increment, decrement, remove, clear } from "../../redux/cartSlice";
import { Container, Col, Row } from "react-bootstrap";
import closeButton from "../../images/closeButton.svg";
import { useNavigate } from "react-router-dom";
import ScrollToTop from "../helpers/ScrollToTop";

const MenuCart: React.FC<any> = ({ isCart, setIsCart }) => {
  const navigate = useNavigate();
  const cart = useSelector((state: any) => state.cart);
  const dispatch = useDispatch();

  const [cartSize, setCartSize]: any = useState();
  const [cartPrice, setCartPrice]: any = useState();
  useEffect(() => {
    let tempSize = 0;
    let tempPrice = 0;
    cart.map((item: any) => {
      tempSize = tempSize + item.quantity;
      tempPrice = tempPrice + item.price * item.quantity;
    });
    setCartSize(tempSize);
    setCartPrice(tempPrice);
  }, [cart]);

  const fixedDivRef: any = useRef(null);
  const [fixedDivHeight, setFixedDivHeight] = useState(0);

  useEffect(() => {
    if (fixedDivRef.current) {
      const height = fixedDivRef.current.clientHeight;
      setFixedDivHeight(height);
    }
  }, []);
  return (
    <div>
      <div
        className="cartIconWrapper"
        onClick={() => {
          setIsCart(!isCart);
        }}
      >
        <img className="cartIcon" src={cartIcon} alt="" />
        {cartSize > 0 && <div className="cartSize">{cartSize}</div>}
      </div>
      <div
        className={isCart ? "cartWrapper cartWrapperVisible" : "cartWrapper"}
      >
        <div
          className="cartWrapperScroll"
          style={{ paddingBottom: fixedDivHeight }}
        >
          {" "}
          <Container>
            <Row>
              <Col className="cartHeader">
                <div className="cartHeaderText">
                  Cart <span>{"  " + cartSize}</span>
                </div>
                <div className="cartClose">
                  <img
                    src={closeButton}
                    alt=""
                    onClick={() => {
                      setIsCart(false);
                    }}
                  />
                </div>
              </Col>
            </Row>
          </Container>
          <div className="cartLine"></div>
          {cart.map((cartItem: any) => {
            return (
              <>
                <Container>
                  <Row>
                    <Col>
                      <div className="cartProduct">
                        <img src={cartItem.img} alt="" />

                        <div className="cardProductRightWrapper">
                          {" "}
                          <div className="cartProductTitle">
                            <div>{cartItem.name}</div>
                            <div>
                              <img
                                src={closeButton}
                                alt=""
                                onClick={() => {
                                  dispatch(remove(cartItem.id));
                                }}
                              />
                            </div>
                          </div>
                          <div className="cartProductButtonsWrapper">
                            <div className="cartProductButtons">
                              <button
                                className="actionButton"
                                disabled={cartItem.quantity === 1}
                                onClick={() => {
                                  dispatch(decrement(cartItem.id));
                                }}
                              >
                                -
                              </button>
                              <div className="cartProductQuantity">
                                {cartItem.quantity}
                              </div>
                              <button
                                className="actionButton"
                                onClick={() => {
                                  dispatch(increment(cartItem.id));
                                }}
                              >
                                +
                              </button>
                            </div>
                            <div className="cartItemPrice">
                              {cartItem.quantity * cartItem.price}$
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Container>
                <div className="cartProductLine"></div>
              </>
            );
          })}
        </div>
        <div className="cartBottom" ref={fixedDivRef}>
          <Container>
            <Row>
              <Container>
                <div className="cartBottomPrice">
                  <div className="cartBottomPriceRow">
                    <div className="cartBottomShippingLeft">Shipping</div>
                    <div className="cartBottomShippingRight">
                      CALCULATED AT CHECKOUT
                    </div>
                  </div>
                  <div className="cartBottomPriceLine"></div>
                  <div className="cartBottomPriceRow">
                    <div className="cartBottomSubtotalLeft">
                      <strong>Subtotal</strong>
                    </div>
                    <div className="cartBottomSubtotalRight">${cartPrice}</div>
                  </div>
                </div>
                <div
                  className="cartBottomCheckout"
                  onClick={() => {
                    setIsCart(false);
                    navigate("/checkout");
                  }}
                >
                  Checkout
                </div>
                <div
                  className="cartBottomContinueCheckout"
                  onClick={() => {
                    setIsCart(false);
                  }}
                >
                  Or continue shopping
                </div>
              </Container>
            </Row>
          </Container>
        </div>
      </div>
    </div>
  );
};

export default MenuCart;
