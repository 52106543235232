import React, { useState, useRef, useEffect } from "react";
import "./Shop.css";
import { Container, Col, Row } from "react-bootstrap";
import OutsideClickHandler from "react-outside-click-handler";
import { useDispatch } from "react-redux";
import { addToCart } from "../../redux/cartSlice";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { items } from "../../items";
import { useLocation } from "react-router-dom";
import ScrollToTop from "../../shared/helpers/ScrollToTop";
import { useWindowWidth } from "../../shared/helpers/windowWidth";
import BlurImage from "../../shared/BlurImage";

const ShopBody = () => {
  const windowWidth = useWindowWidth();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [finalItems, setFinalItems]: any = useState(items);
  const sortItems: any = ["FEATURED", "PRICE", "RELEASE DATE"];
  const [isSort, setIsSort] = useState(false);
  const [sort, setSort] = useState("");

  const filterItems = [
    { name: "ALL PRODUCTS", type: "" },
    { name: "SKINCARE", type: "skincare" },
    { name: "HAIR", type: "hair" },
    { name: "BODY", type: "body" },
    { name: "BUNDLES & SETS", type: "bundles" },
  ];

  const handleSortClick = (selectedSort: any) => {
    setIsSort(!isSort);
    setSort(selectedSort);

    let sortedArray: any;

    if (selectedSort === "FEATURED") {
      sortedArray = [...finalItems].sort((a, b) => a.order - b.order);
    } else if (selectedSort === "PRICE") {
      sortedArray = [...finalItems].sort((a, b) => a.price - b.price);
    } else if (selectedSort === "RELEASE DATE") {
      sortedArray = [...finalItems].sort((a, b) => {
        const dateA = new Date(a.releaseDate) as any;
        const dateB = new Date(b.releaseDate) as any;
        return dateB - dateA;
      });
    }

    setFinalItems(sortedArray);
  };

  const [isFilter, setIsFilter] = useState(false);
  const [selectedFilter, setSelectedFilter]: any = useState("");

  const handleFilter = (filterValue: any) => {
    setSelectedFilter(filterValue);
    const filtered = items.filter((item: any) =>
      item.type.includes(filterValue)
    );
    setFinalItems(filtered);
  };

  useEffect(() => {
    if (queryParams.get("type")) handleFilter(queryParams.get("type"));
  }, [location.search]);

  useEffect(() => {}, [sort]);

  const mobileShopButtons = (
    <div className="shopButtons">
      <div className="shopButton">
        <div className="shopButtonContainer">
          <div
            className="shopButtonText"
            onClick={() => {
              setIsFilter(!isFilter);
              setIsSort(false);
            }}
          >
            FILTER
          </div>
          <div className={isFilter ? "MenuWrapper show" : "MenuWrapper"}>
            <div className="shopMenu">
              {filterItems.map((filterItem) => {
                return (
                  <div
                    className={
                      filterItem.type === selectedFilter
                        ? "shopMenuItem shopMenuItemSelected"
                        : "shopMenuItem"
                    }
                    onClick={() => {
                      setIsFilter(false);
                      handleFilter(filterItem.type);
                    }}
                  >
                    {filterItem.name}
                  </div>
                );
              })}
            </div>
          </div>{" "}
          <div className={isSort ? "MenuWrapper show" : "MenuWrapper"}>
            <div className="shopMenu">
              {sortItems.map((sortItem: any) => {
                return (
                  <div
                    className={
                      sortItem === sort
                        ? "shopMenuItem shopMenuItemSelected"
                        : "shopMenuItem"
                    }
                    onClick={() => handleSortClick(sortItem)}
                  >
                    {sortItem}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>

      <div className="shopButtonsLine"></div>
      <div className="shopButton">
        <div className="shopButtonContainer">
          <div
            className="shopButtonText"
            onClick={() => {
              setIsFilter(false);
              setIsSort(!isSort);
            }}
          >
            SORT
          </div>
        </div>
      </div>
    </div>
  );

  const laptopShopButtons = (
    <div className="shopButtons shopButtonsMobile">
      <div className="shopButton">
        <div className="shopButtonContainer">
          <div
            className="shopButtonText"
            onClick={() => {
              setIsFilter(!isFilter);
              setIsSort(false);
            }}
          >
            FILTER
          </div>
          <div className={isFilter ? "MenuWrapper show" : "MenuWrapper"}>
            <div className="shopMenu">
              {filterItems.map((filterItem) => {
                return (
                  <div
                    className={
                      filterItem.type === selectedFilter
                        ? "shopMenuItem shopMenuItemSelected"
                        : "shopMenuItem"
                    }
                    onClick={() => {
                      setIsFilter(false);
                      handleFilter(filterItem.type);
                    }}
                  >
                    {filterItem.name}
                  </div>
                );
              })}
            </div>
          </div>{" "}
          <div className={isSort ? "MenuWrapper show" : "MenuWrapper"}>
            <div className="shopMenu">
              {sortItems.map((sortItem: any) => {
                return (
                  <div
                    className={
                      sortItem === sort
                        ? "shopMenuItem shopMenuItemSelected"
                        : "shopMenuItem"
                    }
                    onClick={() => handleSortClick(sortItem)}
                  >
                    {sortItem}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>

      <div className="shopButtonsLine"></div>
      <div className="shopButton">
        <div className="shopButtonContainer">
          <div
            className="shopButtonText"
            onClick={() => {
              setIsFilter(false);
              setIsSort(!isSort);
            }}
          >
            SORT
          </div>
        </div>
      </div>
    </div>
  );
  return (
    <div className="shop">
      <h3 className="shopHeader">SHOP</h3>

      {windowWidth < 700 ? mobileShopButtons : laptopShopButtons}
      <div className="shopItemsContainer">
        {finalItems.map((item: any) => {
          return (
            <div
              className={
                windowWidth < 700 ? "shopItem" : "shopItem shopItemLaptop"
              }
              onClick={() => {
                navigate(`/item/${item.id}`);
              }}
            >
              <BlurImage
                src={item.img}
                hash={item.hash}
                className="shopItemImage"
              ></BlurImage>

              <div className="shopItemText">
                <div className="shopItemTextTitle">{item.name}</div>
                <div className="shopItemTextPrice">${item.price}</div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ShopBody;
