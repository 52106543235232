//@ts-nocheck
import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import { items } from "../../items";
import { Container, Col, Row } from "react-bootstrap";
import { Icon } from "@iconify/react";
import { useDispatch } from "react-redux";
import { addToCart } from "../../redux/cartSlice";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import { useWindowWidth } from "../../shared/helpers/windowWidth";

const ShopItem: React.FC<any> = ({ isCart, setIsCart }) => {
  const windowWidth = useWindowWidth();
  const dispatch = useDispatch();
  const { itemId } = useParams();
  const [item, setItem]: any = useState();
  const [selectedColor, setSelectedColor] = useState(""); // State for selected color

  useEffect(() => {
    const foundItem = items.find((findParam: any) => {
      return findParam.id.toString() === itemId;
    });
    setItem(foundItem);
    setSelectedColor(foundItem?.colors?.[0] || ""); // Default to the first color if available
  }, [itemId]);

  const [quantity, setQuantity]: any = useState(1);
  const [selectedTab, setSelectedTab] = useState("description");
  const [navbarHeight, setNavbarHeight]: any = useState();

  useEffect(() => {
    const navbar: any = document.getElementsByClassName("navbarClass")[0];
    setNavbarHeight(navbar.offsetHeight);
  }, []);

  const handleColorChange = (color) => {
    setSelectedColor(color);
  };

  const mobileVersion = (
    <div
      className={
        windowWidth < 700 ? "shopItemPage" : "shopItemPage shopitemPageLaptop"
      }
      style={{ paddingTop: navbarHeight }}
    >
      {item && (
        <>
          <Swiper pagination={true} modules={[Pagination]} className="mySwiper">
            <div className="shopItemCarousel">
              {item.images.map((item: any, index) => {
                return (
                  <SwiperSlide key={index}>
                    <img src={item} alt="" />
                  </SwiperSlide>
                );
              })}
            </div>
          </Swiper>
          <Container>
            <Row>
              <Col>
                <div className="shopItemTitle">{item.name}</div>
                <div className="shopItemPriceQuantity">
                  <div className="shopItemPrice">$ {item.price}</div>
                  <div className="shopItemQuantity">
                    <div
                      className="shopItemQuantityButton"
                      onClick={() => {
                        if (quantity > 0) {
                          setQuantity(quantity - 1);
                        }
                      }}
                    >
                      -
                    </div>
                    <input
                      type="number"
                      value={quantity}
                      onChange={(e: any) => {
                        setQuantity(e.target.value);
                      }}
                    />
                    <div
                      className="shopItemQuantityButton"
                      onClick={() => {
                        setQuantity(quantity + 1);
                      }}
                    >
                      +
                    </div>
                  </div>
                </div>
                {item.colors && item.colors.length > 0 && (
                  <div className="shopItemColors">
                    <div>Select Color:</div>
                    {item.colors.map((color, index) => (
                      <div key={index} className="shopItemColorOption">
                        <input
                          type="radio"
                          id={`color-${color}`}
                          name="color"
                          value={color}
                          checked={selectedColor === color}
                          onChange={() => handleColorChange(color)}
                        />
                        <label htmlFor={`color-${color}`}>{color}</label>
                      </div>
                    ))}
                  </div>
                )}
                <div
                  className="shopItemAddToCartButton"
                  onClick={() => {
                    dispatch(addToCart({ item, quantity, selectedColor }));
                    setIsCart(true);
                  }}
                >
                  ADD TO CART
                </div>
                <div className="shopItemContainer">
                  {item.text && item.text}
                </div>
                <div className="shopItemTabHeadersWrapper">
                  {item.description && (
                    <div
                      className={
                        selectedTab === "description"
                          ? "shopItemTabHeader active"
                          : "shopItemTabHeader"
                      }
                      onClick={() => {
                        setSelectedTab("description");
                      }}
                    >
                      Description
                    </div>
                  )}
                  {item.benifits && (
                    <div
                      className={
                        selectedTab === "benifits"
                          ? "shopItemTabHeader active"
                          : "shopItemTabHeader"
                      }
                      onClick={() => {
                        setSelectedTab("benifits");
                      }}
                    >
                      Benifits
                    </div>
                  )}
                  {item.ritual && (
                    <div
                      className={
                        selectedTab === "ritual"
                          ? "shopItemTabHeader active"
                          : "shopItemTabHeader"
                      }
                      onClick={() => {
                        setSelectedTab("ritual");
                      }}
                    >
                      Ritual
                    </div>
                  )}
                  {item.ingredients && (
                    <div
                      className={
                        selectedTab === "ingredients"
                          ? "shopItemTabHeader active"
                          : "shopItemTabHeader"
                      }
                      onClick={() => {
                        setSelectedTab("ingredients");
                      }}
                    >
                      Ingredients
                    </div>
                  )}
                </div>
                <div className="shopItemTab">{item[selectedTab]}</div>
                <div className="shopItemContainer">
                  {item.extras && item.extras}
                </div>
              </Col>
            </Row>
          </Container>
        </>
      )}
    </div>
  );

  const laptopVersion = (
    <div
      className={"shopItemPage shopitemPageLaptop"}
      style={{ paddingTop: navbarHeight }}
    >
      {item && (
        <>
          <Container>
            <Row>
              <Col className="col-8">
                <Swiper
                  pagination={true}
                  modules={[Pagination]}
                  className="mySwiper"
                >
                  <div className="shopItemCarousel">
                    {item.images.map((item: any, index) => {
                      return (
                        <SwiperSlide key={index}>
                          <img src={item} alt="" />
                        </SwiperSlide>
                      );
                    })}
                  </div>
                </Swiper>
              </Col>
              <Col className="col-4 shopItemLaptopTextButtons">
                <div className="shopItemTitle">{item.name}</div>
                <div className="shopItemPriceQuantity">
                  <div className="shopItemPrice">$ {item.price}</div>
                  <div className="shopItemQuantity">
                    <div
                      className="shopItemQuantityButton"
                      onClick={() => {
                        if (quantity > 0) {
                          setQuantity(quantity - 1);
                        }
                      }}
                    >
                      -
                    </div>
                    <input
                      type="number"
                      value={quantity}
                      onChange={(e: any) => {
                        setQuantity(e.target.value);
                      }}
                    />
                    <div
                      className="shopItemQuantityButton"
                      onClick={() => {
                        setQuantity(quantity + 1);
                      }}
                    >
                      +
                    </div>
                  </div>
                </div>
                {item.colors && item.colors.length > 0 && (
                  <div className="shopItemColors">
                    <div>Select Color:</div>
                    {item.colors.map((color, index) => (
                      <div key={index} className="shopItemColorOption">
                        <input
                          type="radio"
                          id={`color-${color}`}
                          name="color"
                          value={color}
                          checked={selectedColor === color}
                          onChange={() => handleColorChange(color)}
                        />
                        <label htmlFor={`color-${color}`}>{color}</label>
                      </div>
                    ))}
                  </div>
                )}
                <div
                  className="shopItemAddToCartButton"
                  onClick={() => {
                    dispatch(addToCart({ item, quantity, selectedColor }));
                    setIsCart(true);
                  }}
                >
                  ADD TO CART
                </div>
                <div className="shopItemContainer">
                  {item.text && item.text}
                </div>
                <div className="shopItemTabHeadersWrapper">
                  {item.description && (
                    <div
                      className={
                        selectedTab === "description"
                          ? "shopItemTabHeader active"
                          : "shopItemTabHeader"
                      }
                      onClick={() => {
                        setSelectedTab("description");
                      }}
                    >
                      Description
                    </div>
                  )}
                  {item.benifits && (
                    <div
                      className={
                        selectedTab === "benifits"
                          ? "shopItemTabHeader active"
                          : "shopItemTabHeader"
                      }
                      onClick={() => {
                        setSelectedTab("benifits");
                      }}
                    >
                      Benifits
                    </div>
                  )}
                  {item.ritual && (
                    <div
                      className={
                        selectedTab === "ritual"
                          ? "shopItemTabHeader active"
                          : "shopItemTabHeader"
                      }
                      onClick={() => {
                        setSelectedTab("ritual");
                      }}
                    >
                      Ritual
                    </div>
                  )}
                  {item.ingredients && (
                    <div
                      className={
                        selectedTab === "ingredients"
                          ? "shopItemTabHeader active"
                          : "shopItemTabHeader"
                      }
                      onClick={() => {
                        setSelectedTab("ingredients");
                      }}
                    >
                      Ingredients
                    </div>
                  )}
                </div>
                <div className="shopItemTab">{item[selectedTab]}</div>
                <div className="shopItemContainer">
                  {item.extras && item.extras}
                </div>{" "}
              </Col>
            </Row>
            <Row className="shopItemRow"></Row>
          </Container>
        </>
      )}
    </div>
  );

  if (windowWidth < 700) {
    return mobileVersion;
  } else return laptopVersion;
};

export default ShopItem;
