//@ts-nocheck
import React, { useState, useEffect } from "react";
import "./SmallPages.css";
import aboutUs from "../../images/aboutUs.jpg";
import { Container, Col, Row } from "react-bootstrap";
import ConfirmationModal from "../checkout/ConfirmationModal";

const Contact = () => {
  const [navbarHeight, setNavbarHeight]: any = useState();

  useEffect(() => {
    const navbar: any = document.getElementsByClassName("navbarClass")[0];
    setNavbarHeight(navbar.offsetHeight);
  }, []);

  const fields = [
    { name: "name", label: "Name" },
    { name: "email", label: "Email" },
    { name: "phoneNumber", label: "Phone Number" },
  ];

  const [formData, setFormData]: any = useState({
    email: "",
    phoneNumber: "",
    name: "",
    message: "",
  });

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormData((prevFormData: any) => ({
      ...prevFormData,
      [name]: value,
    }));
  };
  const handleSubmit = (e: any) => {
    e.preventDefault();
    let formattedString = "";

    for (const [key, value] of Object.entries(formData)) {
      if (value.trim() !== "") {
        // Exclude empty values
        formattedString += `${key}: ${value} - \n`; // Add a line for each key-value pair
      }
    }
    const config = {
      SecureToken: "35bde4d2-d1c9-48c2-b0e4-54a397328ff2",
      To: "skinsoulsales@hotmail.com",
      From: "devskinsoul@gmail.com",
      Subject: "New skinsoul order",
      Body: formattedString,
    };
    if (window.Email) {
      window.Email.send(config).then((message: any) => {
        setShowModal(true);
      });
    }
  };
  const [selectedField, setSelectedField]: any = useState(false);
  const [showModal, setShowModal] = useState(false);

  const handleCloseModal = () => {
    setShowModal(false);
  };
  return (
    <div style={{ paddingTop: navbarHeight, paddingBottom: "40px" }}>
      <Container className="contactPageContainer">
        <Row>
          <Col>
            <div className="contactPage">
              <h2 className="contactPageheader">
                <strong>
                  We'd love to <br></br>hear from you
                </strong>
              </h2>
              Have a question or feedback? We'd love to hear from you. Please
              use the contact form below to get in touch, or reach out to us
              directly via email at skinsoulleb@hotmail.com. You can also
              connect with us on Instagram or WhatsApp for any inquiries.
            </div>
          </Col>
        </Row>
        <form onSubmit={handleSubmit}>
          <Row className="checkoutRow">
            {fields.map((field: any) => (
              <Col
                className={
                  selectedField === field.name
                    ? "checkoutInput selectedInput"
                    : formData[field.name] !== ""
                    ? "checkoutInput selectedInput"
                    : "checkoutInput"
                }
                xs={12}
                md={12}
                lg={6}
                key={field.name}
              >
                <input
                  type="text"
                  id={field.name}
                  name={field.name}
                  value={formData[field.name]}
                  onChange={handleChange}
                  onFocus={() => setSelectedField(field.name)}
                  onBlur={() => {
                    setSelectedField(false);
                  }}
                />
                <label htmlFor="">{field.label}</label>
              </Col>
            ))}
            <Col
              className={
                selectedField === "message"
                  ? "checkoutInput selectedInput"
                  : formData["message"] !== ""
                  ? "checkoutInput selectedInput"
                  : "checkoutInput"
              }
              xs={12}
              md={12}
              lg={12}
              key={"message"}
            >
              <textarea
                id={"message"}
                name={"message"}
                value={formData["message"]}
                onChange={handleChange}
                onFocus={() => setSelectedField("message")}
                onBlur={() => {
                  setSelectedField(false);
                }}
                rows={5}
              />
              <label htmlFor="">{"Message"}</label>
              <button className="contactFormSubmit" type="submit">
                Send
              </button>
            </Col>
          </Row>
        </form>
      </Container>
      {console.log(showModal)}
      <ConfirmationModal
        isOpen={showModal}
        onClose={handleCloseModal}
        h2Text="Your Message Has Been Received"
        pText=""
      />
    </div>
  );
};

export default Contact;
