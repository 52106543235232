import React, { useState, useEffect } from "react";
import "./Checkout.css";
import { Container, Col, Row } from "react-bootstrap";
import { FaChevronRight, FaChevronDown, FaChevronUp } from "react-icons/fa";
import cartIcon from "../../images/cart.png";
import { useSelector } from "react-redux";
import ConfirmationModal from "./ConfirmationModal";
import { useNavigate } from "react-router-dom";
import emailjs from "@emailjs/browser";
import { useDispatch } from "react-redux";
import { clear } from "../../redux/cartSlice";

const CheckoutBody = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [navbarHeight, setNavbarHeight]: any = useState();

  useEffect(() => {
    const navbar: any = document.getElementsByClassName("navbarClass")[0];
    setNavbarHeight(navbar.offsetHeight);
  }, []);

  const fields = [
    { name: "firstName", label: "First Name" },
    { name: "lastName", label: "Last Name" },
    { name: "address", label: "Address" },
    { name: "apartment", label: "Apartment" },
    { name: "city", label: "City" },
    { name: "postalCode", label: "Postal Code" },
    { name: "note", label: "Note" },
  ];

  const [formData, setFormData]: any = useState({
    email: "",
    phoneNumber: "",
    firstName: "",
    lastName: "",
    address: "",
    apartment: "",
    city: "",
    postalCode: "",
    discoutCode: "",
    note: "",
  });

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormData((prevFormData: any) => ({
      ...prevFormData,
      [name]: value,
    }));
  };
  const cart = useSelector((state: any) => state.cart);
  
  const [cartPrice, setCartPrice]: any = useState();
  const [discountedCartPrice, setDiscountedCartPrice]: any = useState();
  useEffect(() => {
    let tempPrice = 0;
    cart.map((item: any) => {
      tempPrice = tempPrice + item.price * item.quantity;
    });

    setCartPrice(tempPrice);
    setDiscountedCartPrice(tempPrice);
  }, [cart]);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    // Do something with the formData, e.g., send it to an API
  
    const serviceId = "service_gmueiyp";
    const templateId = "template_m8f2cgw";
    const publicKey = "kffZ16vxY0Xr5Qxgy";
  
    console.log(cart);
  
    let formattedItems = cart
      .map((item: any, index: number) => {
        const colorText = item.selectedColor ? ` / ${item.selectedColor}` : '';
        return `${item.name}: ${item.quantity}${colorText}`;
      })
      ?.join(" - ");
  
    console.log(formData);
    const returnFormData = {
      ...formData,
      formattedItems: formattedItems,
      totalPrice: cartPrice.toString(),
      discountedPrice: discountedCartPrice,
    };
    console.log(returnFormData);
    setShowModal(true);
  
    // Uncomment and use emailjs if needed
    emailjs
      .send(serviceId, templateId, returnFormData, publicKey)
      .then((response) => {
        setShowModal(true);
        setFormData({
          email: "",
          phoneNumber: "",
          firstName: "",
          lastName: "",
          address: "",
          apartment: "",
          city: "",
          postalCode: "",
          discoutCode: "",
          note: "",
        });
        dispatch(clear());
      })
      .catch((error) => {
        console.log("Error sending email: ", error);
      });
  
    console.log(formData.firstName);
    console.log(formData.lastName);
  
    // Uncomment and use emailjs if needed
    emailjs
      .send(
        serviceId,
        "template_a63plfh",
        {
          firstName: formData.firstName,
          lastName: formData.lastName,
          email: formData.email,
        },
        publicKey
      )
      .then((response) => {
        dispatch(clear());
      })
      .catch((error) => {
        console.log("Error sending email: ", error);
      });
  };
  

  const stages = ["Cart", "Information", "Payment"];
  const [stage, setStage] = useState(0);

  const [isSummaryOpen, setIsSummaryOpen] = useState(false);

  const toggleOrderSummary = () => {
    setIsSummaryOpen(!isSummaryOpen);
  };

  const [selectedField, setSelectedField]: any = useState(false);

  const [showModal, setShowModal] = useState(false);

  const handleCloseModal = () => {
    setShowModal(false);
    navigate("/");
  };

  const [discountSuccess, setDiscountSuccess] = useState(false);
  const [discountFail, setDiscountFail] = useState(false);
  const discountCodes = [
    { code: "SKINSOUL25", value: 25 },
    // Add more discount codes as needed
  ];

  const applyDiscount = () => {
    // Check if a discount code has been entered
    if (formData.discoutCode) {
      const enteredCode = formData.discoutCode.toUpperCase();

      // Search for the entered code in the discountCodes list
      const discount = discountCodes.find((code) => code.code === enteredCode);

      if (discount) {
        // Calculate the new discounted cart price
        const discountedCartPrice =
          cartPrice - cartPrice * (discount.value / 100);
        setDiscountedCartPrice(discountedCartPrice);
        setDiscountSuccess(true);
        setDiscountFail(false);
      } else {
        console.log("got");
        setDiscountFail(true);
        setDiscountSuccess(false);
      }

      // Clear the discount code input field after applying
      setFormData({ ...formData, discountCode: "" });
    }
  };

  return (
    <div style={{ paddingTop: navbarHeight }}>
      <div className="checkoutBody">
        <div className="checkoutSummary">
          <Container>
            <div className="checkoutSummaryHeader">
              <div className="checkoutSummaryLeft">
                <img
                  className="checkoutSummaryCart"
                  src={cartIcon}
                  alt="Cart"
                />
                <span>Show order summary</span>
                <div
                  className="checkoutSummaryDown"
                  onClick={toggleOrderSummary}
                >
                  {isSummaryOpen ? (
                    <FaChevronUp size={"10px"}></FaChevronUp>
                  ) : (
                    <FaChevronDown size={"10px"}></FaChevronDown>
                  )}
                </div>
              </div>
              <div className="checkoutSummaryRight">
                <strong>{cartPrice}$</strong>
              </div>
            </div>
          </Container>
        </div>
        <div
          className={
            isSummaryOpen ? "checkoutOrderSummary show" : "checkoutOrderSummary"
          }
        >
          <Container>
            {cart.map((cartItem: any) => {
              return (
                <>
                  <div className="cartProduct">
                    <div className="checkoutCartProduct">
                      <div className="checkoutCartItemLeft">
                        <div className="checkoutCartItemImage">
                          <img src={cartItem.img} alt="Product" />
                          <div className="checkoutCartItembadge">
                            <span className="checkoutCartItemBadgeText">
                              {cartItem.quantity}
                            </span>
                          </div>
                        </div>
                        <div>
                          <strong>{cartItem.name}</strong>
                        </div>
                      </div>
                      <div className="checkoutCartItemRight">
                        <strong>{cartItem.price * cartItem.quantity}$</strong>
                      </div>
                    </div>
                  </div>
                  <div className="cartProductLine"></div>
                </>
              );
            })}
            <div className="checkoutCartPrices">
              <div className="checkoutCartTotal">
                <div className="checkoutCartTotalLeft">Subtotal</div>
                <h5>
                  <strong>{cartPrice}$</strong>
                </h5>
              </div>
            </div>
          </Container>
        </div>
        <Container>
          {stages.map((stageName, index) => (
            <span
              className="checkoutBreadcrumbs"
              key={index}
              style={{
                fontWeight:
                  stage >= index - 1 && index !== 0 ? "bold" : "normal",
              }}
            >
              {stageName}
              {index !== stages.length - 1 && (
                <span>
                  {" "}
                  <FaChevronRight size={10} />{" "}
                </span>
              )}
            </span>
          ))}
        </Container>
        <form onSubmit={handleSubmit}>
          {stage === 0 && (
            <Container>
              <Row className="checkoutRow">
                <Col className="checkoutHeader" xs={12} md={12} lg={12}>
                  <h6> Contact Information</h6>
                </Col>

                <Col
                  className={
                    selectedField === "phoneNumber"
                      ? "checkoutInput selectedInput"
                      : formData["phoneNumber"] !== ""
                      ? "checkoutInput selectedInput"
                      : "checkoutInput"
                  }
                  xs={12}
                  md={12}
                  lg={6}
                  key={"phoneNumber"}
                >
                  <input
                    type="text"
                    id={"phoneNumber"}
                    name={"phoneNumber"}
                    value={formData["phoneNumber"]}
                    onChange={handleChange}
                    onFocus={() => setSelectedField("phoneNumber")}
                    onBlur={() => {
                      setSelectedField(false);
                    }}
                  />
                  <label htmlFor="">{"Phone number"}</label>
                </Col>
                <Col
                  className={
                    selectedField === "email"
                      ? "checkoutInput selectedInput"
                      : formData["email"] !== ""
                      ? "checkoutInput selectedInput"
                      : "checkoutInput"
                  }
                  xs={12}
                  md={12}
                  lg={6}
                  key={"email"}
                >
                  <input
                    type="text"
                    id={"email"}
                    name={"email"}
                    value={formData["email"]}
                    onChange={handleChange}
                    onFocus={() => setSelectedField("email")}
                    onBlur={() => {
                      setSelectedField(false);
                    }}
                  />
                  <label htmlFor="">{"Email"}</label>
                </Col>
              </Row>
              <Row className="checkoutRow">
                <Col className="checkoutHeader" xs={12} md={12} lg={12}>
                  <h6>Shipping Address</h6>
                </Col>

                {fields.map((field: any) => (
                  <Col
                    className={
                      selectedField === field.name
                        ? "checkoutInput selectedInput"
                        : formData[field.name] !== ""
                        ? "checkoutInput selectedInput"
                        : "checkoutInput"
                    }
                    xs={12}
                    md={6}
                    lg={6}
                    key={field.name}
                  >
                    <input
                      type="text"
                      id={field.name}
                      name={field.name}
                      value={formData[field.name]}
                      onChange={handleChange}
                      onFocus={() => setSelectedField(field.name)}
                      onBlur={() => {
                        setSelectedField(false);
                      }}
                    />
                    <label htmlFor="">{field.label}</label>
                  </Col>
                ))}
              </Row>
            </Container>
          )}
          {stage === 1 && (
            <Container>
              {" "}
              <Row className="checkoutRow discoutCodeRow"></Row>
              <h6>Details</h6>
              <div className="checkoutShipping">
                <div className="checkoutShippingRow">
                  <div className="checkoutShippingRowLeft">
                    <div>Contact</div>
                    {formData.phoneNumber} <span>/</span> {formData.email}
                  </div>
                  <div
                    className="checkoutShippingRowRight"
                    onClick={() => {
                      setStage(0);
                    }}
                  >
                    Change
                  </div>
                </div>
                <div className="cartProductLine"></div>
                <div className="checkoutShippingRow">
                  <div className="checkoutShippingRowLeft">
                    <div>Address</div>
                    {[
                      formData.address,
                      formData.apartment,
                      formData.city,
                      formData.postalCode,
                    ]
                      .filter(Boolean)
                      .join(", ")}
                  </div>
                  <div
                    className="checkoutShippingRowRight"
                    onClick={() => {
                      setStage(0);
                    }}
                  >
                    Change
                  </div>
                </div>
              </div>
              <h6>Shipping method</h6>
              <div className="checkoutShipping checkoutShippingShip">
                <div className="checkoutShippingRow">
                  <div className="checkoutShippingRowLeft checkoutShippingRowLeftShipping">
                    <div className="checkoutShippingSelectedCircle">
                      <div className="checkoutShippingSelectedCircleInside"></div>
                    </div>
                    Local Delivery
                  </div>
                  <div className="checkoutShippingRowRight">4$</div>
                </div>
              </div>
              <Row className="checkoutRow discoutCodeRow">
                <Col xs={12} md={12} lg={12}>
                  <h6>Discount code</h6>
                </Col>
                <Col className="discountCodeContainer">
                  <div
                    className={
                      selectedField === "discoutCode"
                        ? "checkoutInput selectedInput"
                        : formData["discoutCode"] !== ""
                        ? "checkoutInput selectedInput"
                        : "checkoutInput"
                    }
                    key={"discoutCode"}
                  >
                    {" "}
                    <input
                      type="text"
                      id={"discoutCode"}
                      name={"discoutCode"}
                      value={formData["discoutCode"]}
                      onChange={handleChange}
                      onFocus={() => setSelectedField("discoutCode")}
                      onBlur={() => {
                        setSelectedField(false);
                      }}
                    />
                    <label htmlFor="">{"Discount code"}</label>
                  </div>

                  <div
                    className="discountCodeRight"
                    onClick={() => {
                      console.log("Tes");
                      applyDiscount();
                    }}
                  >
                    <FaChevronRight size={16} />
                  </div>
                </Col>
              </Row>
              <div className="discountSpans">
                {discountSuccess && (
                  <span style={{ color: "green" }}>
                    Discount applied successfully!
                  </span>
                )}
                {discountFail && (
                  <span style={{ color: "red" }}>Discount code not valid.</span>
                )}
              </div>
              <h6>Payment method</h6>
              <div className="checkoutShipping checkoutShippingShip">
                <div className="checkoutShippingRow">
                  <div className="checkoutShippingRowLeft checkoutShippingRowLeftShipping">
                    <div className="checkoutShippingSelectedCircle">
                      <div className="checkoutShippingSelectedCircleInside"></div>
                    </div>
                    Cash on delivery
                  </div>
                  <div className="checkoutShippingRowRight">
                    {discountedCartPrice + 4}$
                  </div>
                </div>
              </div>
            </Container>
          )}

          <Container>
            <div
              className="checkoutContinueButton"
              onClick={() => {
                if (stage !== 1) {
                  setStage(stage + 1);
                }
              }}
            >
              {stage === 0 ? (
                <div className="continueToPaymentButton">
                  Continue to payment
                </div>
              ) : (
                <button className="finaliseButton" type="submit">
                  Finalise purchase
                </button>
              )}
            </div>
            {stage !== 0 && (
              <div
                className="checkoutBackButton"
                onClick={() => {
                  setStage(stage - 1);
                }}
              >
                Go back
              </div>
            )}
          </Container>
        </form>
      </div>
      <ConfirmationModal
        isOpen={showModal}
        onClose={handleCloseModal}
        h2Text="Your Order Has Been Received"
        pText=""
      />
    </div>
  );
};

export default CheckoutBody;
