import React, { useState, useEffect } from "react";
import "./SmallPages.css";
import aboutUs from "../../images/aboutUs.jpg";
import { Container, Col, Row } from "react-bootstrap";

const PrivacyPolicy = () => {
  const [navbarHeight, setNavbarHeight]: any = useState();

  useEffect(() => {
    const navbar: any = document.getElementsByClassName("navbarClass")[0];
    setNavbarHeight(navbar.offsetHeight);
  }, []);

  const privacyItems = [
    {
      question: "Information We Collect",
      answer:
        "We want to assure you that we do not collect any personal information from our website visitors unless provided voluntarily. We respect your privacy and only collect the necessary data to fulfill your requests or improve your browsing experience.",
    },
    {
      question: "Use of Information",
      answer:
        "As we do not collect personal information, we do not use or disclose any data for marketing purposes or share it with third parties.",
    },

    {
      question: "Cookies and Tracking Technologies",
      answer:
        "We may use cookies or similar technologies to enhance your browsing experience. These technologies collect non-personal information such as your IP address, browser type, and device information. They help us analyze website traffic, customize content, and improve our website's functionality. You have the option to disable cookies in your browser settings if you prefer not to have this data collected.",
    },
    {
      question: "Data Security",
      answer:
        "We implement reasonable security measures to protect any personal information you may provide. However, please note that no method of transmission over the internet or electronic storage is 100% secure. We cannot guarantee the absolute security of your data, but we are committed to taking appropriate measures to prevent unauthorized access, use, or disclosure.",
    },
    {
      question: "Data Retention",
      answer:
        "We will retain your personal information for as long as necessary to fulfill the purposes outlined in this Privacy Policy, unless a longer retention period is required or permitted by law. Once the information is no longer needed, we will securely dispose of it in accordance with applicable regulations.",
    },
  ];
  return (
    <div style={{ paddingTop: navbarHeight, paddingBottom: "30px" }}>
      <Container>
        <Row>
          <Col>
            <div className="contactPage shippingPage">
              <h3 className="contactPageheader">
                <strong>Privacy Policy</strong>
              </h3>
              {privacyItems.map((item: any, index: any) => {
                return (
                  <>
                    <h5>{item.question}</h5>
                    {item.answer}
                    {index !== privacyItems.length - 1 && (
                      <div className="shippingPageLine"></div>
                    )}
                  </>
                );
              })}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default PrivacyPolicy;
