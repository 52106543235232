import React, { useState, useEffect, useRef } from "react";
import { motion } from "framer-motion";
import img1 from "../../images/instaImg1.jpg"
import img2 from "../../images/instaImg2.jpg"
import img3 from "../../images/instaImg3.jpg"
import img4 from "../../images/instaImg4.jpg"
import img5 from "../../images/instaImg5.jpg"
import img6 from "../../images/instaImg6.jpg"
const HomeInsta = () => {
  const [instaData, setInstaData] = useState([]);

  const getStaticProps = async () => {
    const url =
      "https://graph.instagram.com/me/media?fields=id,media_url,timestamp,media_type,permalink,username&access_token=IGQWRPdGl0SUQxT3dGZAVdqUVdXeEZAVMHpRNWstSlB4c2Y3ZAE1sY2syb3pMa19BeXJVMGxxYjBrSW5EcWFLcEpqTkVlSFhBaWwtOXZAFb3k3SjNfb3lxM0xmaHh5bDNvUk5VY2tQVG1qX3AwZA2l2MWVoN0hkeVg4Tk0ZD";
    try {
      const data = await fetch(url);
      const feed = await data.json();
      setInstaData(feed.data || []);
    } catch (error) {
      console.error("Error fetching Instagram data:", error);
      setInstaData([]);
    }
  };

  useEffect(() => {
    getStaticProps();
  }, []);

  const [width, setWidth] = useState(0);
  const carousel:any = useRef(null);

  useEffect(() => {
    if (carousel.current) {
      setWidth(carousel.current.scrollWidth - carousel.current.offsetWidth);
    }
  }, [instaData]);

  const staticInstaData = [
    {
      media_url: img1,
      permalink: "https://www.instagram.com/skinsoul.lb/p/C-AZiIVMW4K/",
      media_type: "IMAGE",
    },
    {
      media_url: img2,
      permalink: "https://www.instagram.com/skinsoul.lb/p/C8gsfXcM5wD/",
      media_type: "IMAGE",
    },
    {
      media_url: img3,
      permalink: "https://www.instagram.com/skinsoul.lb/p/C5aYGEzMNUo/",
      media_type: "IMAGE",
    },
    {
      media_url: img4,
      permalink: "https://www.instagram.com/skinsoul.lb/p/CwM3LYHMCmQ/",
      media_type: "IMAGE",
    },
    {
      media_url: img5,
      permalink: "https://www.instagram.com/skinsoul.lb/p/C0Omdx9M2Xy/",
      media_type: "IMAGE",
    },
    {
      media_url: img6,
      permalink: "https://www.instagram.com/skinsoul.lb/p/CtOGgcbslOg/",
      media_type: "IMAGE",
    },
  
  ];

  const displayData = instaData.length > 0 ? instaData : staticInstaData;

  return (
    <div className="homeInsta">
      <div className="homeCarouselContainer">
        <h3 className="homeFeaturedCollection mb-5">
          FIND US<br></br> ON INSTAGRAM
        </h3>
        <motion.div
          ref={carousel}
          className="homeCarouselInsta"
          whileTap={{ cursor: "grabbing" }}
        >
          <motion.div
            drag="x"
            dragConstraints={{ right: 0, left: -width }}
            className="homeInnerCarousel"
          >
            {displayData.map((item, index) => {
              if (item.media_type !== "IMAGE") {
                return null;
              }
              return (
                <motion.div className="item" key={index}>
                  <div
                    onClick={() => {
                      window.open(item.permalink);
                    }}
                    className="homeSliderImage"
                    style={{
                      backgroundImage: `url(${item.media_url})`,
                    }}
                  ></div>
                </motion.div>
              );
            })}
          </motion.div>
        </motion.div>
      </div>
    </div>
  );
};

export default HomeInsta;
