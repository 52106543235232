import roseGua from "./images/store/roseGua.jpg";
import roseGua2 from "./images/store/roseGua2.jpg";
import roseGua3 from "./images/store/roseGua3.jpg";
import roseGua4 from "./images/store/roseGua4.jpg";
import roseGua5 from "./images/store/roseGua5.jpg";
import roseGua6 from "./images/store/roseGua6.jpg";
import jadeGua from "./images/store/jadeGua.jpg";
import jadeGua2 from "./images/store/jadeGua2.jpg";
import jadeGua3 from "./images/store/jadeGua3.jpg";
import jadeGua4 from "./images/store/jadeGua4.jpg";
import amethystGua from "./images/store/amethystGua.jpg";
import roseRoller from "./images/store/roseRoller.jpg";
import roseRoller2 from "./images/store/roseRoller2.jpg";
import roseRoller3 from "./images/store/roseRoller3.jpg";
import jadeRoller from "./images/store/jadeRoller.jpg";
import jadeRoller2 from "./images/store/jadeRoller2.jpg";
import jadeRoller3 from "./images/store/jadeRoller3.jpg";
import jadeRoller4 from "./images/store/jadeRoller4.jpg";
import jadeRoller5 from "./images/store/jadeRoller5.jpeg";
import jadeRoller6 from "./images/store/jadeRoller6.jpg";
import amethystRoller from "./images/store/amethystRoller.jpg";
import roseSet from "./images/store/roseSet.jpg";
import roseSet2 from "./images/store/roseSet2.jpg";
import roseSet3 from "./images/store/roseSet3.jpg";
import roseSet4 from "./images/store/roseSet4.jpg";
import roseSet5 from "./images/store/roseSet5.jpg";
import jadeSet from "./images/store/jadeSet.jpg";
import jadeSet2 from "./images/store/jadeSet2.jpg";
import jadeSet3 from "./images/store/jadeSet3.jpg";
import jadeSet4 from "./images/store/jadeSet4.jpg";
import jadeSet5 from "./images/store/jadeSet5.jpg";
import jadeSet6 from "./images/store/jadeSet6.jpg";
import amethystSet from "./images/store/amethystSet.jpg";
import amethystSet2 from "./images/store/amethystSet2.jpg";
import amethystSet3 from "./images/store/amethystSet3.jpg";
import amethystSet4 from "./images/store/amethystSet4.jpg";
import hairComb from "./images/store/hairComb.jpg";
import hairComb2 from "./images/store/hairComb2.jpg";
import hairComb3 from "./images/store/hairComb3.jpg";
import hairComb4 from "./images/store/hairComb4.jpg";
import hairComb5 from "./images/store/hairComb5.jpg";
import facemaskBrown1 from "./images/store/facemaskBrown1.jpeg";
import facemaskBrown2 from "./images/store/facemaskBrown2.jpeg";
import facemaskGray1 from "./images/store/facemaskGray1.jpeg";
import facemaskGray2 from "./images/store/facemaskGray2.jpeg";
import facemaskYellow1 from "./images/store/facemaskYellow1.jpeg";
import facemaskYellow2 from "./images/store/facemaskYellow2.jpeg";
import facemaskRice1 from "./images/store/facemaskRice1.jpeg";
import facemaskRice2 from "./images/store/facemaskRice2.jpeg";
import bambooBrush from "./images/store/bodyBrush.jpg";
import bianStone1 from "./images/bianStone1.jpg";
import bianStone2 from "./images/bianStone2.jpg";
import bianStone3 from "./images/bianStone3.jpg";
import facialRoller1 from "./images/facialIceRoller1.jpg";
import facialRoller2 from "./images/facialIceRoller2.jpg";
import facialRoller3 from "./images/facialIceRoller3.jpg";
import hairScalp1 from "./images/hairScalp1.jpg";
import hairScalp2 from "./images/hairScalp2.jpg";
import hairScalp3 from "./images/hairScalp3.jpg";



export const items: any = [
  {
    id: 0,
    name: "Rose Quartz Gua Sha",
    price: 16,
    img: roseGua,
    type: "skincare",
    order: "10",
    release: "2002-08-02",
    hash: "LFQI_RRiyY%M.SR5R5bw*0tRVsV@",
    images: [roseGua, roseGua2, roseGua3, roseGua4, roseGua5, roseGua6],
    text: (
      <>
        Elevate your skincare routine with Skin Soul’s Rose Quartz Crystal Heart
        Gua Sha Tool.
      </>
    ),
    description:
      "Elevate your skincare routine with Skin Soul’s Rose Quartz Crystal Heart Gua Sha Tool. Heart-shaped to target large and small facial areas, leading to a more youthful, radiant complexion.",
    benifits: (
      <>
        Improve fine lines and wrinkles<br></br> Promotes lymphatic drainage
        <br></br> Relieves tension from muscles<br></br> Contours and lift the
        face
      </>
    ),
    ritual: (
      <>
        <strong>Step 1:</strong> Cleanse and Tone. It's best to perform Gua sha
        on clean skin. If you use toner, apply it after cleansing. Also, make
        sure your hands and the tool itself is clean. A drop of cleanser and
        water are all you need.
        <br></br>
        <strong>Step 2:</strong> Apply a Facial Oil. Place a generous amount of
        face oil to your face, neck, and chest. Oil will help the tool glide
        more easily, but if you prefer a cream or water-based lotion that is OK.
        Just note that you may need to reapply more throughout the process.{" "}
        <br></br>
        <strong>Step 3:</strong> Perform Gua Sha. Holding the tool at a 30 to
        45-degree angle, very gently pull/scrape it across your skin. As a rule,
        all movements should be upward and follow lymphatic flow. Repeat each
        movement three to five times before moving onto the next area of your
        skin <br></br>
        <strong>Step 4:</strong> Remove Excess and Complete Regimen. If you
        want, you can massage the remaining product into your skin with your
        hands, or you can wipe away any residual oil or cream with a damp cloth.
        Afterward, complete your go-to skincare routine.
      </>
    ),
    extras: (
      <>
        <strong>100% Authenticity </strong> <br></br>Our Rose Quartz Gua Sha has
        been designed and handcrafted with love. Made from premium 100% rose
        quartz stone from Brazil <br></br>
        <br></br>
        <strong>Premium Quality</strong>
        <br></br> Our Gua sha has undergone a strict quality control process and
        has been individually inspected. We are dedicated to providing the
        highest of quality as your happiness and satisfaction are our
        priorities.<br></br>
        <br></br> <strong>WHAT'S INCLUDED:</strong> <br></br>Premium Quartz Gua
        Sha <br></br>Skin Soul Beauty Box<br></br> <strong>N.B:</strong> As
        these products are fragile, please handle with care
      </>
    ),
  },
  {
    id: 1,
    name: "Green Aventurine Gua Sha ",
    price: 16,
    img: jadeGua,
    type: "skincare",
    order: "12",
    release: "2002-08-02",
    images: [jadeGua, jadeGua2, jadeGua3, jadeGua4],
    hash: "LHRV|Ut7^,WAxuayayj[_4WB9Et7",
    description:
      "Elevate your skincare routine with Skin Soul’s Green Aventurine  Quartz Crystal Heart Gua Sha Tool. Heart-shaped to target large and small facial areas, leading to a more youthful, radiant complexion.",
    benifits: (
      <>
        Improve fine lines and wrinkles<br></br> Promotes lymphatic drainage
        <br></br> Relieves tension from muscles<br></br> Contours and lift the
        face
      </>
    ),
    ritual: (
      <>
        <strong>Step 1:</strong> Cleanse and Tone. It's best to perform Gua sha
        on clean skin. If you use toner, apply it after cleansing. Also, make
        sure your hands and the tool itself is clean. A drop of cleanser and
        water are all you need.
        <br></br>
        <strong>Step 2:</strong> Apply a Facial Oil. Place a generous amount of
        face oil to your face, neck, and chest. Oil will help the tool glide
        more easily, but if you prefer a cream or water-based lotion that is OK.
        Just note that you may need to reapply more throughout the process.{" "}
        <br></br>
        <strong>Step 3:</strong> Perform Gua Sha. Holding the tool at a 30 to
        45-degree angle, very gently pull/scrape it across your skin. As a rule,
        all movements should be upward and follow lymphatic flow. Repeat each
        movement three to five times before moving onto the next area of your
        skin <br></br>
        <strong>Step 4:</strong> Remove Excess and Complete Regimen. If you
        want, you can massage the remaining product into your skin with your
        hands, or you can wipe away any residual oil or cream with a damp cloth.
        Afterward, complete your go-to skincare routine.
      </>
    ),
    extras: (
      <>
        <strong>100% Authenticity</strong> <br></br>Our Green Aventurine Gua Sha
        has been designed and handcrafted with love. Made from premium 100%
        Green Aventurine stone from Brazil
        <br></br>
        <br></br>
        <strong>Premium Quality</strong>
        <br></br> Our Gua sha has undergone a strict quality control process and
        has been individually inspected. We are dedicated to providing the
        highest of quality as your happiness and satisfaction are our
        priorities.<br></br>
        <br></br> <strong>WHAT'S INCLUDED:</strong> <br></br>Premium Quartz Gua
        Sha<br></br> Skin Soul Beauty Box
        <br></br> <strong>N.B:</strong> As these products are fragile, please
        handle with care
      </>
    ),
  },

  {
    id: 2,
    name: "Rose Quartz Face Roller",
    price: 17,
    img: roseRoller,
    type: "skincare",
    order: "9",
    release: "2002-08-02",
    images: [roseRoller, roseRoller2, roseRoller3],
    hash: "LDRV-0adj]of%MRPaeoz.mozWBoL",
    description:
      "Our Face Roller is made from authentic jade, known for its cooling and calming effects. It features dual rollers, one large and one small, for different areas of the face. The smooth action and sturdy handle ensure ease of use.",
    benifits: (
      <>
        Improve elasticity of the skin <br></br>Reduce the appearance of
        puffiness and fine lines <br></br> Ease facial muscle tension <br></br>
        Help product absorption
      </>
    ),
    ritual: (
      <>
        <strong>Step 1:</strong> Wash your face with your favorite cleanser,
        face roller works best with a fresh cleaned face.
        <br></br>
        <strong>Step 2:</strong> Add skincare products. You can use your face
        roller to apply moisturizer, serums or oils to your skin more evenly.
        <br></br>
        <strong>Step 3:</strong>Roll down and out. The direction you roll in
        matters — roll outwards from the center of your face. Around the eyes,
        for example, roll away from your eyes to decrease puffiness, also you
        can find the instructions on skin soul’s box.
        <br></br>
        <strong>Step 4:</strong> Clean your face roller. In order to avoid any
        bacteria, it is important to clean your face roller after using it,
        simply use a small quantity of soap on your hands, lather it with water
        and clean the face roller and rinse it with water afterword.
      </>
    ),
    extras: (
      <>
        <strong>N.B:</strong> As these products are fragile, please handle with
        care
      </>
    ),
  },
  {
    id: 3,
    name: "Jade Face Roller",
    price: 17,
    img: jadeRoller,
    type: "skincare",
    order: "11",
    release: "2002-08-02",
    hash: "LfPQ56In-qkBM_j?xbWB_4%MM{j[",
    images: [
      jadeRoller,
      jadeRoller2,
      jadeRoller3,
      jadeRoller4,
      jadeRoller5,
      jadeRoller6,
    ],
    description:
      "Our Face Roller is made from authentic jade, known for its cooling and calming effects. It features dual rollers, one large and one small, for different areas of the face. The smooth action and sturdy handle ensure ease of use.",
    benifits: (
      <>
        Improve elasticity of the skin <br></br>Reduce the appearance of
        puffiness and fine lines <br></br> Ease facial muscle tension <br></br>
        Help product absorption
      </>
    ),
    ritual: (
      <>
        <strong>Step 1:</strong> Wash your face with your favorite cleanser,
        face roller works best with a fresh cleaned face.
        <br></br>
        <strong>Step 2:</strong> Add skincare products. You can use your face
        roller to apply moisturizer, serums or oils to your skin more evenly.
        <br></br>
        <strong>Step 3:</strong>Roll down and out. The direction you roll in
        matters — roll outwards from the center of your face. Around the eyes,
        for example, roll away from your eyes to decrease puffiness, also you
        can find the instructions on skin soul’s box.
        <br></br>
        <strong>Step 4:</strong> Clean your face roller. In order to avoid any
        bacteria, it is important to clean your face roller after using it,
        simply use a small quantity of soap on your hands, lather it with water
        and clean the face roller and rinse it with water afterword.
      </>
    ),
    extras: (
      <>
        <strong>N.B:</strong> As these products are fragile, please handle with
        care
      </>
    ),
  },

  {
    id: 4,
    name: "Hair Comb",
    price: 8,
    img: hairComb,
    type: "hair",
    order: "5",
    release: "2002-10-02",
    hash: "LbNK3eae?dfPxvfPaxWC_4ogITay",
    images: [hairComb, hairComb2, hairComb3, hairComb4, hairComb5],
    description:
      "Our Hair Comb is handcrafted from natural wood and features wide-toothed bristles that glide through the hair effortlessly. It is suitable for all hair types and helps detangle without causing breakage or static.",
    benifits:
      "jade comb is specifically designed to massage the scalp, reduce tension, and promote relaxation. It helps increase the blood circulation of the scalp promoting healthy hair growth, shine and reduced hair fall.",
    ritual: (
      <>
        Step 1: first start by brushing your hair with a regular brush to
        detangle your hair <br></br>Step 2: you could use it with a hair oil or
        dry hair before shower <br></br>Step 3: bring your comb to the front of
        the hairline. Start with little strokes toward the back of your head,
        working in small areas. stroke all the way down from the front of the
        hairline to the back<br></br> Do this at least once a day for 10
        minutes. Right away, you’ll feel more relaxed, energized, and focused.
        Over time, you’ll start seeing your hair getting stronger and shinier
        <br></br>Step 4: after you are done using the hair comb, wash your hair
        jade comb with a soap and water
      </>
    ),
  },
  {
    id: 5,
    name: "Bamboo Brush",
    price: 9,
    img: bambooBrush,
    type: "body",
    order: "4",
    release: "2002-09-02",
    images: [bambooBrush],
    hash: "LUQJ1Gb^M|Di_4IoI9xugPV@s*V[",
    description:
      "Our Bamboo Brush is sustainably made from natural bamboo and features soft bristles. It is designed to gently exfoliate the skin, remove impurities, and promote a smooth and radiant complexion.",
    benifits:
      "The bamboo bristles provide gentle exfoliation, removing dead skin cells and promoting cell turnover. This helps reveal fresh, glowing skin. The brush also stimulates blood circulation and enhances the effectiveness of skincare products.",
    ritual:
      "Start with a clean, damp face. Wet the brush and apply a small amount of cleanser or facial scrub. Gently massage your face in circular motions, focusing on areas that need extra exfoliation. Rinse your face with warm water and pat dry. Clean the brush after each use and let it air dry.",
  },
  {
    id: 6,
    name: "I'm Coconut Face Sheet Mask",
    price: 4,
    img: facemaskGray1,
    type: "skincare",
    order: "6",
    release: "2002-10-09",
    hash: "LdQmCsof~qj[s:j[WCay-;WBM{kB",
    images: [facemaskGray1, facemaskGray2],
    description:
      "Our Face Mask is a luxurious blend of natural ingredients designed to nourish and revitalize the skin. It contains antioxidants, vitamins, and minerals that promote a radiant complexion and address specific skin concerns.",
    benifits:
      "Face masks provide deep hydration, detoxification, and skin rejuvenation. They can help reduce the appearance of pores, even out skin tone, and promote a youthful glow. Regular use improves the overall health and appearance of the skin.",
    ritual:
      "Apply a thin, even layer of the face mask to cleansed skin, avoiding the delicate eye area. Leave it on for the recommended time, allowing the ingredients to work their magic. Relax and enjoy some downtime while the mask works. Rinse off with warm water and pat dry. Use 1-2 times per week, or as directed.",
  },
  {
    id: 7,
    name: "I'm Rice Face Sheet Mask",
    price: 4,
    img: facemaskRice1,
    type: "skincare",
    order: "6",
    release: "2002-10-09",
    hash: "LURfh2t7~qRjWBj[ofWB?bRjIUt7",
    images: [facemaskRice1, facemaskRice2],
    description:
      "Our Face Mask is a luxurious blend of natural ingredients designed to nourish and revitalize the skin. It contains antioxidants, vitamins, and minerals that promote a radiant complexion and address specific skin concerns.",
    benifits:
      "Face masks provide deep hydration, detoxification, and skin rejuvenation. They can help reduce the appearance of pores, even out skin tone, and promote a youthful glow. Regular use improves the overall health and appearance of the skin.",
    ritual:
      "Apply a thin, even layer of the face mask to cleansed skin, avoiding the delicate eye area. Leave it on for the recommended time, allowing the ingredients to work their magic. Relax and enjoy some downtime while the mask works. Rinse off with warm water and pat dry. Use 1-2 times per week, or as directed.",
  },
  {
    id: 8,
    name: "Vitamin C Face Sheet Mask",
    price: 4,
    img: facemaskYellow1,
    type: "skincare",
    order: "6",
    release: "2002-10-09",
    hash: "LRS6JMog?dacoGt8kDRh%OofRPWB",
    images: [facemaskYellow1, facemaskYellow2],
    description:
      "Our Face Mask is a luxurious blend of natural ingredients designed to nourish and revitalize the skin. It contains antioxidants, vitamins, and minerals that promote a radiant complexion and address specific skin concerns.",
    benifits:
      "Face masks provide deep hydration, detoxification, and skin rejuvenation. They can help reduce the appearance of pores, even out skin tone, and promote a youthful glow. Regular use improves the overall health and appearance of the skin.",
    ritual:
      "Apply a thin, even layer of the face mask to cleansed skin, avoiding the delicate eye area. Leave it on for the recommended time, allowing the ingredients to work their magic. Relax and enjoy some downtime while the mask works. Rinse off with warm water and pat dry. Use 1-2 times per week, or as directed.",
  },
  {
    id: 9,
    name: "Collagen Face Sheet Mask",
    price: 4,
    img: facemaskBrown1,
    type: "skincare",
    order: "6",
    release: "2002-10-09",
    hash: "LJSFt$oz?^VsnNtRozRO%gofM{R*",
    images: [facemaskBrown1, facemaskBrown2],
    description:
      "Our Face Mask is a luxurious blend of natural ingredients designed to nourish and revitalize the skin. It contains antioxidants, vitamins, and minerals that promote a radiant complexion and address specific skin concerns.",
    benifits:
      "Face masks provide deep hydration, detoxification, and skin rejuvenation. They can help reduce the appearance of pores, even out skin tone, and promote a youthful glow. Regular use improves the overall health and appearance of the skin.",
    ritual:
      "Apply a thin, even layer of the face mask to cleansed skin, avoiding the delicate eye area. Leave it on for the recommended time, allowing the ingredients to work their magic. Relax and enjoy some downtime while the mask works. Rinse off with warm water and pat dry. Use 1-2 times per week, or as directed.",
  },

  {
    id: 10,
    name: "Rose Quartz Facial Set",
    price: 31,
    img: roseSet,
    type: "skincare bundles",
    order: "1",
    release: "2002-10-02",
    hash: "LbRCS$n5b]ozaMs;aybH.mb]aKae",
    images: [roseSet, roseSet2, roseSet3, roseSet4, roseSet5],
    description: (
      <>
        This gorgeous double roller and Gua Sha tool help enhance blood
        circulation, encourage skin cell rejuvenation, promote lymphatic
        drainage, improve product absorption, reduce puffiness, and relief
        tension or stress. <br></br>Regular use is also known to be helpful for
        sagging skin, wrinkles, enlarged pores, and the overall improvement of
        your complexion.
      </>
    ),
    benifits:
      "This set provides the combined benefits of Gua Sha and face rolling techniques. It helps reduce puffiness, promotes lymphatic drainage, improves blood circulation, and enhances product absorption. Regular use can result in a more toned, lifted, and youthful-looking complexion.",
    ritual:
      "After applying your skin care products, gently roll over the roller, or move the Gua Sha tool along your skin in an upwards direction. Use the smaller roller or the Gua Sha tool for the eye area",
    extras: (
      <>
        <strong>WHAT'S INCLUDED:</strong> <br></br>Premium Quartz stone Facial
        Roller<br></br> Premium Quartz Gua Sha Tool<br></br> Skin soul box
        <br></br> <strong>N.B:</strong> As these products are fragile, please
        handle with care
      </>
    ),
  },
  {
    id: 11,
    name: "Jade Set",
    price: 31,
    img: jadeSet,
    type: "skincare bundles",
    order: "2",
    release: "2002-10-02",
    hash: "LgO|CQtR?cV@V@WBoft7?ws:M_Rj",
    images: [jadeSet, jadeSet2, jadeSet3, jadeSet4, jadeSet5, jadeSet6],
    description: (
      <>
        This gorgeous double roller and Gua Sha tool help enhance blood
        circulation, encourage skin cell rejuvenation, promote lymphatic
        drainage, improve product absorption, reduce puffiness, and relief
        tension or stress. <br></br>Regular use is also known to be helpful for
        sagging skin, wrinkles, enlarged pores, and the overall improvement of
        your complexion.
      </>
    ),
    benifits:
      "This set provides the combined benefits of Gua Sha and face rolling techniques. It helps reduce puffiness, promotes lymphatic drainage, improves blood circulation, and enhances product absorption. Regular use can result in a more toned, lifted, and youthful-looking complexion.",
    ritual:
      "After applying your skin care products, gently roll over the roller, or move the Gua Sha tool along your skin in an upwards direction. Use the smaller roller or the Gua Sha tool for the eye area",
    extras: (
      <>
        <strong>WHAT'S INCLUDED:</strong> <br></br>Premium Quartz stone Facial
        Roller<br></br> Premium Quartz Gua Sha Tool<br></br> Skin soul box
        <br></br> <strong>N.B:</strong> As these products are fragile, please
        handle with care
      </>
    ),
  },
  {
    id: 12,
    name: "Amethyst Set",
    price: 31,
    img: amethystSet,
    type: "skincare bundles",
    order: "3",
    release: "2002-10-03",
    hash: "LROM~r?b~qM{00%M%MtQD%xuM{%M",
    images: [amethystSet, amethystSet2, amethystSet3, amethystSet4],
    description: (
      <>
        This gorgeous double roller and Gua Sha tool help enhance blood
        circulation, encourage skin cell rejuvenation, promote lymphatic
        drainage, improve product absorption, reduce puffiness, and relief
        tension or stress. <br></br>Regular use is also known to be helpful for
        sagging skin, wrinkles, enlarged pores, and the overall improvement of
        your complexion.
      </>
    ),
    benifits:
      "This set provides the combined benefits of Gua Sha and face rolling techniques. It helps reduce puffiness, promotes lymphatic drainage, improves blood circulation, and enhances product absorption. Regular use can result in a more toned, lifted, and youthful-looking complexion.",
    ritual:
      "After applying your skin care products, gently roll over the roller, or move the Gua Sha tool along your skin in an upwards direction. Use the smaller roller or the Gua Sha tool for the eye area",
    extras: (
      <>
        <strong>WHAT'S INCLUDED:</strong> <br></br>Premium Quartz stone Facial
        Roller<br></br> Premium Quartz Gua Sha Tool<br></br> Skin soul box
        <br></br> <strong>N.B:</strong> As these products are fragile, please
        handle with care
      </>
    ),
  }, {
    id: 13,
    name: "Hair Scalp Massage Scrubber",
    price: 7,
    img: hairScalp1,
    images: [hairScalp1, hairScalp2, hairScalp3],
    type: "hair",
    order: "15",
    release: "2023-01-01",
    hash: "LFRzUI~W_NG3~q?cD$xZRj-;WBoL",
    description:
      "Transform your hair care routine with our innovative Hair scalp Massage Scrubber! Designed to invigorate your scalp and enhance your shampooing experience, this essential tool promotes healthier hair growth and ensures a thorough, enjoyable cleanse. Perfect for all hair types, our hair scrubber will leave your scalp feeling revitalized and your hair looking its best.",
    benifits: (
      <>
        Promotes Healthy Hair Growth: Stimulates blood flow to the scalp,
        encouraging healthier hair growth.
        <br></br>
        Enhances Cleanliness: Effectively removes buildup and dandruff, leaving
        your scalp clean and refreshed.
        <br></br>
        Soothes & Relaxes: Provides a relaxing scalp massage, reducing stress
        and tension.
        <br></br>
        Improves Product Absorption: Enhances the effectiveness of shampoos,
        conditioners, and other hair treatments by ensuring even distribution.
      </>
    ),
    ritual: (
      <>
        <strong>Step 1:</strong> Wet Hair: Start with wet hair and apply your
        favorite shampoo.
        <br></br>
        <strong>Step 2:</strong> Scrub: Hold the hair scrubber by the handle and
        gently massage your scalp in circular motions, focusing on areas with
        buildup or tension.
        <br></br>
        <strong>Step 3:</strong> Rinse: Continue to scrub as you rinse out the
        shampoo, ensuring all product is removed from your scalp and hair.
      </>
    ),
    extras: (
      <>
        <strong>High-Quality Material:</strong> Made from durable, waterproof
        silicone, ensuring longevity and easy maintenance.
        <br></br>
        <strong>Ergonomic Design:</strong> The comfortable, non-slip handle fits
        perfectly in your hand, making it easy to use.
        <br></br>
        <strong>Soft Bristles:</strong> Gentle silicone bristles provide a
        soothing massage while effectively removing dirt, oil, and product
        buildup.
        <br></br>
        <strong>Versatile Use:</strong> Suitable for use in the shower with your
        favorite shampoo or conditioner, and dry for a relaxing scalp massage.
        <br></br>
        <strong>Easy to Clean:</strong> Simply rinse with water and let air dry
        after each use.
      </>
    ),
  },
  {
    id: 14,
    name: "Facial Ice Roller",
    price: 10,
    img: facialRoller1,
    images: [facialRoller1, facialRoller2, facialRoller3],
    type: "skincare",
    order: "16",
    release: "2023-01-01",
    hash: "LHRV|Ut7^,WAxuayayj[_4WB9Et7",
    colors: ["Pink", "Purple"],
    description:
      "Introducing our Silicone facial Ice Roller – the perfect addition to your skincare arsenal! Designed to deliver a cooling and soothing sensation, this versatile tool is crafted from premium silicone to offer maximum comfort and effectiveness. Ideal for all skin types, our ice roller helps rejuvenate your complexion and enhance your overall skincare routine.",
    benifits: (
      <>
        Soothes & Calms: Ideal for sensitive or irritated skin, the cold therapy
        provides immediate relief.
        <br></br>
        Reduces Puffiness: Effectively reduces under-eye bags and facial
        swelling, giving you a refreshed and youthful appearance.
        <br></br>
        Improves Skin Texture: Helps tighten and tone skin, promoting a
        smoother, more radiant complexion.
        <br></br>
        Enhances Product Absorption: Use after applying serums or moisturizers
        to boost absorption and effectiveness.
      </>
    ),
    ritual: (
      <>
        <strong>Step 1:</strong> Fill: Open the silicone roller and fill it with
        water.
        <br></br>
        <strong>Step 2:</strong> Freeze: Place the filled roller in the freezer
        for at least 4 hours.
        <br></br>
        <strong>Step 3:</strong> Cleanse: Start with a clean face, free from
        makeup and impurities.
        <br></br>
        <strong>Step 4:</strong> Roll: Gently roll the ice roller over your face
        and neck in upward and outward motions for 5-10 minutes.
        <br></br>
        <strong>Step 5:</strong> Repeat: For best results, use daily or as
        needed.
      </>
    ),
    extras: (
      <>
        <strong>High-Quality Silicone:</strong> Made from food-grade, BPA-free
        silicone, ensuring safety and durability.
        <br></br>
        <strong>Flexible & Ergonomic:</strong> The soft, flexible design
        conforms to the contours of your face for a comfortable and effective
        rolling experience.
        <br></br>
        <strong>Portable & Lightweight:</strong> Compact and easy to store,
        perfect for use at home or on-the-go.
        <br></br>
        <strong>Multi-Functional:</strong> Reduces puffiness, soothes
        irritation, minimizes pores, and improves blood circulation.
        <br></br>
        <strong>Easy to Use:</strong> Fill with water and freeze for a few
        hours, then roll over your skin for an instant cooling effect.
      </>
    ),
  },
  {
    id: 15,
    name: "Gua Sha Bian Stone",
    price: 18,
    img: bianStone1,
    images:[bianStone1, bianStone2, bianStone3],
    type: "skincare",
    order: "17",
    release: "2023-01-01",
    hash: "LURfh2t7~qRjWBj[ofWB?bRjIUt7",
    description:
      "Transform your skincare and wellness routine with our premium Gua Sha Bian Stone. Expertly crafted from authentic Bian stone, this versatile tool is designed to enhance both facial and body care, offering a multitude of benefits for your skin and overall well-being. Experience the timeless benefits of traditional Gua Sha massage with our Bian Stone. Elevate your self-care routine and reveal your natural, glowing beauty from head to toe.",
    benifits: (
      <>
        Improves Circulation: Helps to stimulate blood flow, delivering oxygen
        and nutrients to the skin for a healthy, radiant glow.
        <br></br>
        Reduces Tension: Eases muscle tension and promotes relaxation, perfect
        for unwinding after a long day.
        <br></br>
        Enhances Skincare: Works in harmony with your favorite serums and oils,
        aiding in deeper absorption and maximizing their benefits.
        <br></br>
        Detoxifies: Supports lymphatic drainage, helping to remove toxins and
        reduce inflammation.
      </>
    ),
    ritual: (
      <>
        <strong>For the Face:</strong>
        <br></br>
        <strong>Step 1:</strong> Apply your favorite facial oil or serum.
        <br></br>
        <strong>Step 2:</strong> Hold the Gua Sha at a 15-degree angle and
        gently glide it upwards and outwards over your skin.
        <br></br>
        <strong>Step 3:</strong> Focus on areas prone to puffiness and tension,
        such as the jawline, cheeks, and forehead.
        <br></br>
        <br></br>
        <strong>For the Body:</strong>
        <br></br>
        <strong>Step 1:</strong> Use with a body oil or lotion.
        <br></br>
        <strong>Step 2:</strong> Apply gentle pressure and stroke in long,
        sweeping motions along muscles and joints.
        <br></br>
        <strong>Step 3:</strong> Perfect for areas like the neck, shoulders, and
        back.
      </>
    ),
    extras: (
      <>
        <strong>Authentic Bian Stone:</strong> Made from genuine Bian stone,
        known for its healing properties and historical significance in
        traditional Chinese medicine.
        <br></br>
        <strong>Multi-Purpose Use:</strong> Ideal for both face and body,
        promoting better circulation, reducing muscle tension, and enhancing
        skin elasticity.
        <br></br>
        <strong>Ergonomic Design:</strong> The carefully designed shape fits
        comfortably in your hand, making it easy to glide over your skin for an
        effective massage experience.
        <br></br>
        <strong>Natural Beauty Boost:</strong> Regular use helps to improve skin
        tone, reduce puffiness, and diminish the appearance of fine lines and
        wrinkles.
      </>
    ),
  },
];
