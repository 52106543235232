import React from "react";
import { Container, Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import ScrollToTop from "../../shared/helpers/ScrollToTop";
import { useWindowWidth } from "../../shared/helpers/windowWidth";

const HomeAbout = () => {
  const windowWidth = useWindowWidth();
  const navigate = useNavigate();
  return (
    <div className="homeAboutWrapper">
      <Container>
        <Row>
          <Col>
            <div
              className={
                windowWidth < 700 ? "homeAbout" : "homeAbout homeAboutDesktop"
              }
            >
              <h3 className="homeAboutHeader">ABOUT US</h3>
              <div className="homeAboutText">
                {windowWidth < 700 ? (
                  <>
                    At Skin Soul, we combine ancient Chinese beauty secrets with
                    modern skincare knowledge to help you achieve beautiful,
                    healthy-looking skin.
                  </>
                ) : (
                  <>
                    At SkinSoul, we believe that skincare is more than just a
                    routine – it's a soulful journey to discover and celebrate
                    your true beauty. Our passion lies in curating exceptional
                    skincare products that nurture both the skin and the soul,
                    empowering you to embrace your unique radiance.
                  </>
                )}
              </div>
              <div
                className="homeFeaturedButton shopAllButton mt-2 mb-4"
                onClick={() => {
                  navigate("/story");
                }}
              >
                LEARN MORE
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default HomeAbout;
